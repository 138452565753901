// import { useMemo } from 'react';
import { useFeathers } from '../use-feathers/use-feathers';
import { Feathers as feathers } from '../feathers/feathers-configure';

import { useParams, useNavigate } from 'react-router-dom';
import { ServiceTypes } from '@inkcloud/icapi-types';

interface UseDetailOptions<L extends keyof ServiceTypes> {
  api: {
    service: L;
    method: 'find' | 'get';
    query: Record<string, unknown>;
    id?: string;
  };
}

type Return<L extends keyof ServiceTypes> = {
  id: string;
  state: ReturnType<typeof useFeathers>[0];
  reload: () => void;
  mutateData: (data: any) => Promise<void>;
  closeDrawer: () => void;
};

export function useDetailPage<L extends keyof ServiceTypes>(
  options: UseDetailOptions<L>
): Return<L> {
  const { api } = options;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const idResolved = api.id || id;
  const [state, reload] = useFeathers(
    api.method,
    api.service,
    [idResolved, { query: api.query }],
    true
  );

  return {
    id: idResolved,
    state,
    reload,
    mutateData: async function (data: any): Promise<void> {
      const service = feathers.service(api.service);
      if (idResolved) {
        await service.patch(idResolved, data);
      } else {
        await service.create(data);
      }
    },
    closeDrawer: () => navigate(-1),
  };
}

export default useDetailPage;
