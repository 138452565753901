import * as React from 'react';

import NumberFormat from 'react-number-format';
import { currencySymbol } from '@inkcloud/shared-ui';

export interface IRateCardTableProps {
  currency: string;
  locale: string;
  rates: {
    quantity: string;
    unitCost: number;
    unitSell: number;
    unitMargin: number;
  }[];
}

const RateCardTable: React.FunctionComponent<IRateCardTableProps> = (props) => {
  const { rates, currency, locale } = props;

  return (
    <table
      tremor-id="tremor-raw"
      className="w-full caption-bottom border-b border-transparent dark:border-transparent"
    >
      {/* <caption className="mt-3 px-3 text-gray-500 dark:text-gray-500 text-left text-xs">
      ¹Includes 10,000 trackable expenses/month, USD 0.10 for each additional
      expense.
    </caption> */}
      <thead className="">
        <tr className="[&amp;_td:last-child]:pr-4 [&amp;_th:last-child]:pr-4 [&amp;_td:first-child]:pl-4 [&amp;_th:first-child]:pl-4">
          <th className="border-b px-4 py-3.5 text-gray-900 dark:text-gray-50 border-gray-200 dark:border-gray-800 text-right text-xs font-medium uppercase">
            Quantity
          </th>
          <th className="border-b px-4 py-3.5 text-gray-900 dark:text-gray-50 border-gray-200 dark:border-gray-800 text-right text-xs font-medium uppercase">
            Unit cost
          </th>
          <th className="border-b px-4 py-3.5 text-gray-900 dark:text-gray-50 border-gray-200 dark:border-gray-800 text-right text-xs font-medium uppercase">
            Unit sell
          </th>
          <th className="border-b px-4 py-3.5 text-gray-900 dark:text-gray-50 border-gray-200 dark:border-gray-800 text-right text-xs font-medium uppercase">
            Unit margin
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-gray-800">
        {rates.map((rate) => (
          <tr className="[&amp;_td:last-child]:pr-4 [&amp;_th:last-child]:pr-4 [&amp;_td:first-child]:pl-4 [&amp;_th:first-child]:pl-4">
            <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">
              {rate.quantity}
            </td>
            <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">
              {!rate.unitCost && rate.unitCost !== 0 ? (
                '-'
              ) : (
                <NumberFormat
                  value={rate.unitCost ?? 0.0}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={currencySymbol(currency, locale)}
                />
              )}
            </td>
            <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">
              <NumberFormat
                value={rate.unitSell ?? 0.0}
                decimalScale={2}
                displayType={'text'}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={currencySymbol(currency, locale)}
              />
            </td>
            <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">
              {!rate.unitCost && rate.unitCost !== 0 ? (
                '-'
              ) : (
                <NumberFormat
                  value={((rate.unitSell - rate.unitCost) / rate.unitSell) * 100}
                  decimalScale={2}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  suffix="%"
                />
              )}
            </td>
          </tr>
        ))}
        {/* <tr className="[&amp;_td:last-child]:pr-4 [&amp;_th:last-child]:pr-4 [&amp;_td:first-child]:pl-4 [&amp;_th:first-child]:pl-4">
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">
            10 - 49
          </td>
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">$22.25</td>
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">$42.00</td>
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">12%</td>
        </tr>
        <tr className="[&amp;_td:last-child]:pr-4 [&amp;_th:last-child]:pr-4 [&amp;_td:first-child]:pl-4 [&amp;_th:first-child]:pl-4">
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">50+</td>
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">$20.00</td>
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">$40.00</td>
          <td className="p-4 text-sm text-gray-600 dark:text-gray-400 py-2.5 text-right">50%</td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default RateCardTable;
