import * as React from 'react';
import Breadcrumbs, { IBreadcrumbsProps } from '../Breadcrumbs/Breadcrumbs';

export interface IPageHeadingProps {
  breadcrumbs?: IBreadcrumbsProps['pages'];
  title: string;
  actions?: React.ReactNode;
}

export const PageHeading: React.FunctionComponent<IPageHeadingProps> = (props) => {
  const { breadcrumbs, title, actions } = props;
  return (
    <div>
      {breadcrumbs && <Breadcrumbs pages={breadcrumbs} />}

      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
            {title}
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">{actions}</div>
      </div>
    </div>
  );
};

export default PageHeading;
