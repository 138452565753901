import * as React from 'react';
import { useList } from '@inkcloud/shared/hooks';
import ListTable from '../../components/ListTable/ListTable';
import { PageHeading, IPageHeadingProps } from '../../components';

type ListReturn = ReturnType<typeof useList>;

interface IListPageTemplateProps {
  title: string;
  headerActions?: React.ReactNode;
  breadcrumbs?: IPageHeadingProps['breadcrumbs'];
  useListReturn: ListReturn;
  filterBar: React.ReactNode;
  tableColumns: any[];
  onRowClick?: (row: any) => void;
  emptyState?: {
    title?: string;
    description?: string;
    actionButton?: React.ReactNode;
  };
}

export const ListPageTemplate: React.FunctionComponent<IListPageTemplateProps> = (props) => {
  const {
    title,
    headerActions,
    breadcrumbs,
    useListReturn,
    tableColumns,
    filterBar,
    onRowClick,
    emptyState,
  } = props;

  const { state } = useListReturn;

  return (
    <div className="p-6">
      <div className="mb-8">
        <PageHeading title={title} actions={headerActions} breadcrumbs={breadcrumbs} />
      </div>

      <div className="bg-white p-4 sm:p-6 lg:rounded-lg lg:border lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full">
        {state.isLoading ? (
          <div className="mt-8 min-h-80 flex flex-col bg-white border shadow-sm rounded-md dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
              <div className="flex justify-center">
                <div
                  className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ListTable
            columns={tableColumns}
            data={state.queryResult}
            filterBar={filterBar}
            emptyState={emptyState}
            onRowClick={onRowClick}
          />
        )}
      </div>
    </div>
  );
};
