import * as React from 'react';
import { useMemo } from 'react';
import {
  RiBookOpenLine,
  RiDatabase2Line,
  RiPencilLine,
  RiPlayListAddLine,
  RiDeleteBin7Line,
} from '@remixicon/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  badgeVariants,
  Badge,
  Breadcrumbs,
  Card,
  FormattedDate,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  ListTable,
  listTableCheckboxColumn,
  BarChart,
  DefinitionList,
} from '@inkcloud/base-ui';
import { useNavigate, useSearchParams, useParams, Link } from 'react-router-dom';
import { useList, useDetailPage } from '@inkcloud/shared/hooks';
import { ModelTypes } from '@inkcloud/icapi-types';
import { cx } from '../../../../../tremor/utils';

interface IDetailPageProps {}

const DetailPage: React.FunctionComponent<IDetailPageProps> = (props) => {
  const navigate = useNavigate();
  const { microsite: id, id: micrositeGroupId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'on-demand';

  const { state: micrositeState } = useDetailPage({
    api: {
      service: 'microsites',
      method: 'get',
      id,
      query: {
        $select: ['name', 'key', 'domain'],
      },
    },
  });

  const { state: detailState } = useDetailPage({
    api: {
      service: 'microsites/customer/groups' as any,
      method: 'get',
      id: micrositeGroupId,
      query: {
        $select: ['name'],
        $populate: [{ path: 'groups', select: ['name'] }],
      },
    },
  });

  const typeSelect = [];
  const useListReturn = useList({
    feathers: [
      'find',
      'microsites/customers',
      [
        {
          query: {
            microsite: id,
            groups: micrositeGroupId,
            $select: ['firstName', 'lastName', 'email', ...typeSelect],
            $populate: [
              // ...typePopulate,
            ],
            // ...typeQuery,
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ staticName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }

      if (v.tenant && v.tenant !== '') {
        filter['business'] = v.tenant;
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const microsite: ModelTypes.Microsites = detailState.data ?? {};
  const { filter, setFilter, state } = useListReturn;

  const handleRowClick = (row) => navigate(`/orders/${row?.original?._id}`);

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Date',
        accessorKey: 'createdAt',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
      {
        header: 'User',
        // accessorKey: 'micrositeUser',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        cell: ({ row }) => {
          return (
            <span>
              {row?.original?.firstName ?? ''} {row?.original?.lastName ?? ''}
            </span>
          );
        },
      },

      {
        header: 'Status',
        accessorKey: 'isHidden',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          const v = getValue();
          return <Badge variant="success">Active</Badge>;
        },
      },
      {
        header: 'Last edited',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const name = `${detailState.data?.firstName ?? ''} ${detailState.data?.lastName ?? ''}`;

  const data = [
    { date: 'Jan 23', 'This Year': 68560, 'Last Year': 28560 },
    { date: 'Feb 23', 'This Year': 70320, 'Last Year': 30320 },
    { date: 'Mar 23', 'This Year': 80233, 'Last Year': 70233 },
    { date: 'Apr 23', 'This Year': 55123, 'Last Year': 45123 },
    { date: 'May 23', 'This Year': 56000, 'Last Year': 80600 },
    { date: 'Jun 23', 'This Year': 100000, 'Last Year': 85390 },
    { date: 'Jul 23', 'This Year': 85390, 'Last Year': 45340 },
    { date: 'Aug 23', 'This Year': 80100, 'Last Year': 70120 },
    { date: 'Sep 23', 'This Year': 75090, 'Last Year': 69450 },
    { date: 'Oct 23', 'This Year': 71080, 'Last Year': 63345 },
    { date: 'Nov 23', 'This Year': 61210, 'Last Year': 100330 },
    { date: 'Dec 23', 'This Year': 60143, 'Last Year': 45321 },
  ];

  function valueFormatter(number) {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
      notation: 'compact',
      compactDisplay: 'short',
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(number);
  }

  const msGroup = detailState.data ?? {};

  return (
    <div className="bg-gray-50 px-8 p-4 sm:p-6  lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full ">
      <div className="mb-8">
        <div>
          <Breadcrumbs
            pages={[
              { name: 'Microsites', href: '/microsites', current: false },
              { name: micrositeState?.data?.name, href: `/microsites/${id}`, current: true },
              { name: 'Groups', href: `/microsites/${id}/groups`, current: false },
              {
                name: msGroup.name,
                href: `/microsites/${id}/groups/${micrositeGroupId}`,
                current: true,
              },
            ]}
          />
          <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
                {msGroup.name}
              </h2>
            </div>
            <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Edit
              </button>
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Publish
              </button>
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-8 items-stretch">
          <div className="w-2/3">
            <Card className="w-full h-full ">
              <DefinitionList
                nested
                data={[
                  { name: 'Name', value: msGroup.name },
                  {
                    name: 'Order Approval',
                    value: msGroup.orderApprovalRequired ? 'Required' : 'Not Required',
                  },
                ]}
              />
            </Card>
          </div>
          <div className="w-2/3">
            <Card className="sm:mx-auto sm:max-w-2xl">
              <h3 className="ml-1 mr-1 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                Orders overview
              </h3>
              <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
              </p>
              <BarChart
                data={data}
                index="date"
                categories={['This Year']}
                colors={['blue']}
                valueFormatter={valueFormatter}
                yAxisWidth={50}
                className="mt-6 hidden h-60 sm:block"
              />
              <BarChart
                data={data}
                index="date"
                categories={['This Year']}
                colors={['blue']}
                valueFormatter={valueFormatter}
                showYAxis={false}
                className="mt-4 h-56 sm:hidden"
              />
              {/* <Divider /> */}
              {/* <div className="mb-2 flex items-center space-x-3">
            <Switch id="comparison" onChange={() => setShowComparison(!showComparison)} />
            <label
              htmlFor="comparison"
              className="text-tremor-default text-tremor-content dark:text-dark-tremor-content"
            >
              Show same period last year
            </label>
          </div> */}
            </Card>
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-50 mb-4">
            Users in Group
          </h3>
          <Tabs
            defaultValue="on-demand"
            value={type}
            onValueChange={(v) =>
              setSearchParams((prev) => {
                prev.set('type', v);
                return prev;
              })
            }
          >
            <TabsList variant="line" className="mb-6">
              <TabsTrigger value="on-demand">Active</TabsTrigger>
              <TabsTrigger value="stock">Fulfilled</TabsTrigger>
              <TabsTrigger value="canceled">Canceled</TabsTrigger>
              <TabsTrigger value="all">All</TabsTrigger>
            </TabsList>
            <div className="bg-white p-4 sm:p-6 lg:rounded-lg lg:border lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full">
              <div className="ml-2">
                <TabsContent
                  value="on-demand"
                  className="space-y-2 text-sm leading-7 text-gray-600 dark:text-gray-500"
                >
                  <ListTable
                    columns={tableColumns}
                    data={state.queryResult}
                    filterBar={null}
                    // emptyState={emptyState}
                    onRowClick={handleRowClick}
                  />
                </TabsContent>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
