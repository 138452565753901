/* eslint-disable-next-line */
import { type Application as FeathersApplication } from '@feathersjs/feathers';
import { type ServiceTypes } from '@inkcloud/icapi-types';

let feathers: FeathersApplication;
let icapi: FeathersApplication<ServiceTypes>;
let Feathers: FeathersApplication;

export function feathersConfigure(configuredFeathers: FeathersApplication) {
  if (!feathers) {
    feathers = configuredFeathers;
    Feathers = feathers;
    icapi = configuredFeathers as FeathersApplication<ServiceTypes>;
  }
}


export { feathers, icapi ,Feathers };
