import * as React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface IDefinitionListProps {
  condensed?: boolean;
  nested?: boolean;
  data: {
    name: string | React.ReactNode;
    value: string | React.ReactNode;
  }[];
}

const DefinitionList: React.FunctionComponent<IDefinitionListProps> = (props) => {
  const { data = [], nested = false, condensed = false } = props;
  return (
    <div
      className={classNames(nested ? ' ' : 'mt-6 border-t border-gray-100 dark:border-gray-900')}
    >
      <dl className="divide-y divide-gray-100 dark:divide-gray-900">
        {data.map((item, index) => (
          <div
            key={index}
            className={classNames(
              condensed ? 'py-1' : 'py-4',
              'px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'
            )}
          >
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-300 sm:col-span-2 sm:mt-0 truncate">
              {item.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export { DefinitionList, type IDefinitionListProps };
