import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box, Button, Icon, IconButton, Text, Progress } from '@chakra-ui/react';

import { FaTimes, FaFileUpload } from 'react-icons/fa';

import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

declare const APPENV: { api: { connection: any } };

interface UploadBoxProps {
  tenant: string;
  showFiles?: boolean;
  isMultiple?: boolean;
  api?: string;
  micrositeId?: string;
  onUpload: (files: any) => void;
  btnLabel?: string;
  isDraggable?: boolean;
  isFileRemovable?: boolean;
}

export function UploadBox(props: UploadBoxProps) {
  const {
    tenant,
    showFiles = true,
    isDraggable = true,
    isMultiple = true,
    isFileRemovable = false,
    btnLabel = 'Upload File',
  } = props;
  const [files, setFiles] = useState<any>([]);
  const [isUploading, setIsUploading] = useState(false);

  const [barValue, setBarValue] = useState(0);

  const api = props.api ? props.api : '/uploads-misc';

  const onDrop = useCallback(async (acceptedFiles: any[]) => {
    setFiles(files.concat(acceptedFiles));

    const res = acceptedFiles.map(async (f) => {
      const formData = new FormData();

      formData.append('file', f);
      formData.append('tenantId', tenant);

      if (props.micrositeId) {
        formData.append('microsite', props.micrositeId);
      }

      setIsUploading(true);
      try {
        return await axios.post(`${APPENV.api.connection}${api}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('feathers-jwt'),
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total = 0 } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);

            if (percent < 100) {
              if (percent < 5) {
                percent = 5;
              }

              setBarValue(percent);
            }
          },
        });
      } catch (e) {
        console.error(e);
      }
    });

    const all = await Promise.allSettled(res);
    props.onUpload(all?.map((a: any) => a?.value?.data));
    setIsUploading(false);
  }, []);

  const handleRemoveClick = (idx: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(idx, 1);
    setFiles(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const rootProps = getRootProps() as any;
  return (
    <Box p={3} mb={3} borderRadius="md" borderWidth="1px">
      <Box
        p={3}
        borderRadius="md"
        borderWidth="1px"
        borderStyle="dashed"
        borderColor="blue.400"
        textAlign="center"
        {...rootProps}
      >
        <input {...getInputProps({ multiple: isMultiple })} />
        {isDraggable && (
          <>
            <Box mb={3}>
              <Icon as={FaFileUpload} color="blue.400" boxSize={10} />
            </Box>
            {isDragActive ? (
              <Text fontWeight="bold">Drop the files here ... </Text>
            ) : (
              <Text fontWeight="bold">Drag 'n' drop some files here, or click to select files</Text>
            )}
          </>
        )}
        <Button
          my={3}
          isDisabled={isUploading}
          isLoading={isUploading}
          colorScheme="blue"
          type="button"
        >
          {btnLabel}
        </Button>
        {isUploading && <Progress hasStripe value={barValue} />}
      </Box>

      {showFiles && (
        <Box>
          {files.map((f: any, idx: number) => (
            <Box key={idx} mt={3}>
              {isFileRemovable && (
                <>
                  <IconButton
                    type="button"
                    aria-label="Remove file"
                    size="sm"
                    onClick={(e) => {
                      handleRemoveClick(idx);
                      e.stopPropagation();
                    }}
                    icon={<FaTimes />}
                  />{' '}
                </>
              )}
              {f.name}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default UploadBox;
