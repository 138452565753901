import * as React from 'react';
import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useList, useDataLoaderPaginated } from '@inkcloud/shared/hooks';
import {
  Badge,
  // EmptyStateActionButton,
  listTableCheckboxColumn,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  ListTable,
  ListTabPageTemplate,
  // DefinitionList,
} from '@inkcloud/base-ui';
import { useNavigate } from 'react-router';

import FilterBar from './FilterBar';
import { useSearchParams } from 'react-router-dom';

interface IMicrositeListPageProps {}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MicrositeListPage: React.FunctionComponent<IMicrositeListPageProps> = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'active';

  const typeQuery: any = {};
  const typePopulate: any = [];
  const typeSelect: string[] = [];
  if (type === 'inactive') {
    // typeQuery.inventory = { $ne: true };
  }

  const useListReturn = useList({
    feathers: [
      'find',
      'microsites',
      [
        {
          query: {
            $select: ['key', 'name', ...typeSelect],
            $populate: [
              // {
              //   path: 'business',
              //   select: ['displayBusinessName', 'key'],
              // },
              ...typePopulate,
            ],
            ...typeQuery,
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ name: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }

      if (v.tenant && v.tenant !== '') {
        filter['tenant'] = v.tenant;
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter, state } = useListReturn;

  const { data, isLoading } = useDataLoaderPaginated({
    tenants: {
      ignoreLocationChange: true,
      service: 'tenants' as any,
      // action: 'find',
      query: {
        $sort: { displayBusinessName: 1 },
        $select: ['key', 'displayBusinessName'],
        $limit: 50,
      },
    },
  });

  const handleRowClick = (row) => navigate(`/microsites/${row?.original?._id}`);

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Tenant',
        accessorKey: 'business.key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Key',
        accessorKey: 'key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },

      {
        header: 'Status',
        accessorKey: 'isHidden',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          const v = getValue();
          return <Badge variant="success">Active</Badge>;
        },
      },
      {
        header: 'Last edited',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const tableColumnsStock = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Tenant',
        accessorKey: 'business.key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Key',
        accessorKey: 'key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'On Hand',
        accessorKey: 'inventoryCount',
        enableSorting: true,
        meta: {
          align: 'text-right',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          return <Badge variant="success">{getValue()}</Badge>;
        },
      },

      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          return <Badge variant="success">Active</Badge>;
        },
      },
      {
        header: 'Last edited',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <ListTabPageTemplate
      title="Microsites"
      breadcrumbs={[{ name: 'Microsites', href: '/microsites', current: true }]}
      useListReturn={useListReturn}
      activeTab={type}
      onTabChange={(v) => {
        setSearchParams((prev) => {
          prev.set('type', v);
          return prev;
        });
      }}
      tabs={[
        {
          tabName: 'Active',
          tabValue: 'active',
          filterBar: (
            <FilterBar
              tenants={(data?.tenants as any)?.data ?? []}
              // statuses={status}
              value={filter}
              onChange={(v) => setFilter(v)}
              // value={filter}
            />
          ),
          tableColumns: tableColumns,
          onRowClick: handleRowClick,
        },
        {
          tabName: 'Inactive',
          tabValue: 'inactive',
          filterBar: (
            <FilterBar
              tenants={(data?.tenants as any)?.data ?? []}
              // statuses={status}
              value={filter}
              onChange={(v) => setFilter(v)}
              // value={filter}
            />
          ),
          tableColumns: tableColumnsStock,
          onRowClick: handleRowClick,
        },
      ]}

      // tableColumns={tableColumns}
      // onRowClick={handleRowClick}
    />
  );
};

export default MicrositeListPage;
