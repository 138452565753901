import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useList } from '@inkcloud/shared/hooks';
import {
  Badge,
  FormattedDate,
  ListPageTemplate,
  // IndeterminateCheckbox,
  EmptyStateActionButton,
  listTableCheckboxColumn,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const useListReturn = useList({
    feathers: [
      'find',
      'design-templates',
      [
        {
          query: {
            $select: ['name', 'key', 'createdAt', 'updatedAt', 'tags', 'thumbnails'],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ displayBusinessName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Date',
        accessorKey: 'createdAt',
        enableSorting: true,
        meta: {
          align: 'text-left text-xs',
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
      {
        header: 'Updated',
        accessorKey: 'updatedAt',
        enableSorting: true,

        meta: {
          align: 'text-left',
          columnVisibility: false,
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableSorting: true,
        meta: {
          align: 'text-left  text-xs truncate',
        },
      },
      {
        header: 'Key',
        accessorKey: 'key',
        enableSorting: true,
        meta: {
          align: 'text-left  text-xs truncate',
        },
      },
      {
        header: 'Tags',
        accessorKey: 'tags',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return (
            <div className="flex gap-1 flex-wrap truncate">
              {getValue()?.map((tag: string) => (
                <Badge className=" text-xs" key={tag}>
                  {tag}
                </Badge>
              ))}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Design Templates"
      breadcrumbs={[{ name: 'Design Templates', href: '/design-templates', current: true }]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      emptyState={{
        title: 'No design templates to show',
        description: 'No design templates have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      filterBar={
        <FilterBar
          // tenants={data?.tenants?.data ?? []}
          // statuses={status}
          value={filter}
          onChange={(v) => setFilter(v)}
          // value={filter}
        />
      }
    />
  );
};

export default ListPage;
