import * as React from 'react';
import {
  Card,
  FileUpload,
  RadioGroup,
  RadioGroupItem,
  Label,
  Divider,
  Textarea,
  Button,
} from '@inkcloud/base-ui';

interface IPreflightsDetailPageProps {}

const PreflightsDetailPage: React.FunctionComponent<IPreflightsDetailPageProps> = (props) => {
  const title = 'Preflight Details';
  const [response, setResponse] = React.useState<any>(null);

  return (
    <div className="bg-white p-4 sm:p-6 lg:rounded-lg lg:border lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full">
      <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">{title}</h1>

      <div className="sm:flex gap-6">
        <div className="sm:max-w-4xl sm:w-4xl">
          <Card>
            <dl className="divide-y divide-gray-100 dark:divide-gray-700">
              <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3 sm:gap-2 sm:px-0">
                <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                  Paper
                </dt>
                <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  100# Gloss Book
                </dd>
              </div>
              <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3  sm:px-0">
                <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                  Coating
                </dt>
                <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  None
                </dd>
              </div>
              <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3  sm:px-0">
                <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                  Color
                </dt>
                <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  4/4
                </dd>
              </div>
              <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3  sm:px-0">
                <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                  Sheets
                </dt>
                <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  11
                </dd>
              </div>
            </dl>
            <Divider />
            <div>
              {response}
              <RadioGroup className="flex flex-col gap-6" onValueChange={setResponse}>
                <div className="flex items-start gap-x-3">
                  <RadioGroupItem value="approve" id="radio_51" />
                  <div className="mt-px flex flex-col gap-y-2.5">
                    <Label
                      className="text-sm font-semibold leading-none"
                      htmlFor="radio_51"
                      aria-describedby="radio_51-description"
                    >
                      Approve
                    </Label>
                  </div>
                </div>
                <div className="flex items-start gap-x-3">
                  <RadioGroupItem value="replace-and-approve" id="radio_52" />
                  <div className="mt-px flex flex-col gap-y-2.5">
                    <Label
                      className="text-sm font-semibold leading-none"
                      htmlFor="radio_52"
                      aria-describedby="radio_52-description"
                    >
                      Replace & Approve
                    </Label>
                    <p
                      className="text-sm text-gray-500 dark:text-gray-500"
                      id="radio_52-description"
                    >
                      Upload replacement files and approve the preflight.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-x-3">
                  <RadioGroupItem value="reject-and-allow" id="radio_53" />
                  <div className="mt-px flex flex-col gap-y-2.5">
                    <Label
                      className="text-sm font-semibold leading-none"
                      htmlFor="radio_53"
                      aria-describedby="radio_53-description"
                    >
                      Reject & Allow
                    </Label>
                    <p
                      className="text-sm text-gray-500 dark:text-gray-500"
                      id="radio_53-description"
                    >
                      Reject the preflight and allow the job to proceed.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-x-3">
                  <RadioGroupItem value="reject" id="radio_53" />
                  <div className="mt-px flex flex-col gap-y-2.5">
                    <Label
                      className="text-sm font-semibold leading-none"
                      htmlFor="radio_53"
                      aria-describedby="radio_53-description"
                    >
                      Reject
                    </Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
            {response === 'replace-and-approve' && (
              <>
                <Divider />
                <FileUpload />
              </>
            )}
            {(response === 'reject' || response === 'reject-and-allow') && (
              <>
                <Divider />
                <Textarea placeholder="Describe issues..." />
              </>
            )}

            <Button className="mt-4" variant="primary">
              Save
            </Button>
          </Card>
        </div>
        <Card>
          <img
            src="https://storage.googleapis.com/artwork-thumbs/reg/66e093f2dc0a32239d89782a.jpg"
            alt="artwork thumbnail"
          />
        </Card>
      </div>
    </div>
  );
};
export default PreflightsDetailPage;
