import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useList, useDetailPage } from '@inkcloud/shared/hooks';
import {
  Badge,
  ListPageTemplate,
  EmptyStateActionButton,
  listTableCheckboxColumn,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const { microsite: id } = useParams();
  const navigate = useNavigate();
  const handleRowClick = (row) => navigate(`/microsites/${id}/groups/${row?.original?._id}`);

  const { state: detailState } = useDetailPage({
    api: {
      service: 'microsites',
      method: 'get',
      id,
      query: {
        $select: ['name', 'key'],
      },
    },
  });

  const useListReturn = useList({
    feathers: [
      'find',
      'microsites/customer/groups',
      [
        {
          query: {
            $select: ['name'],
            microsite: id,
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ displayBusinessName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Name',
        accessorKey: 'name',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          return <Badge variant="success">Active</Badge>;
        },
      },
      {
        header: 'Last edited',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Groups"
      breadcrumbs={[
        { name: 'Microsites', href: '/microsites', current: true },
        { name: detailState.data?.name ?? '', href: `/microsites/${id}`, current: true },
        { name: 'Groups', href: `/microsites/${id}/groups`, current: true },
      ]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      onRowClick={handleRowClick}
      emptyState={{
        title: 'No groups to show',
        description: 'No groups have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      filterBar={
        <FilterBar
          // tenants={data?.tenants?.data ?? []}
          // statuses={status}
          value={filter}
          onChange={(v) => setFilter(v)}
          // value={filter}
        />
      }
    />
  );
};

export default ListPage;
