import * as React from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { cx } from '../../utils';
import { useSearchParams } from 'react-router-dom';

interface IPaginationProps {
  data: any;
  // countPerPage: number;
  // currentPage: number;
  // totalItems: number;
  onChangePage: (page: number) => void;
}

const TextButton = ({
  onClick,
  disabled,
  children,
}: {
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
}) => {
  return (
    <button
      type="button"
      className="group rounded-md bg-white p-2 text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-white dark:bg-gray-950 dark:ring-gray-800 hover:dark:bg-gray-900 disabled:hover:dark:bg-gray-950"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const NumberButton = ({
  active,
  onClick,
  children,
  position,
}: {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
  position?: 'left' | 'right';
}) => {
  return (
    <button
      type="button"
      className={cx(
        'min-w-[36px] rounded-md p-2 text-sm text-gray-900 disabled:opacity-50 dark:text-gray-50',
        active
          ? 'bg-blue-500 font-semibold text-white dark:bg-blue-500 dark:text-white'
          : 'hover:bg-gray-50 hover:dark:bg-gray-900',
        position === 'left' ? 'rounded-l-md' : position === 'right' ? 'rounded-r-md' : ''
      )}
      onClick={onClick}
      aria-current={active ? 'page' : undefined}
    >
      {children}
    </button>
  );
};

const MobileButton = ({
  onClick,
  disabled,
  children,
  position,
}: {
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
  position?: 'left' | 'right';
}) => {
  return (
    <button
      type="button"
      className={cx(
        'group p-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-white dark:ring-gray-800 hover:dark:bg-gray-900 disabled:hover:dark:bg-gray-950',
        position === 'left' ? 'rounded-l-md' : position === 'right' ? '-ml-px rounded-r-md' : ''
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const Pagination: React.FunctionComponent<IPaginationProps> = (props: IPaginationProps) => {
  const { data, onChangePage } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  // console.log('searchParams', searchParams);

  if (!data || !data.total) {
    return null;
  }
  const numberOfPages = Math.ceil(data?.total / data?.limit);

  const pageQuery = searchParams.get('page');

  const page = Number(pageQuery) || 1;

  const searchQuery = [];

  for (const [key, value] of searchParams) {
    // console.log('use lists', [key, value]);

    searchQuery.push({
      [key]: value,
    });
  }

  const updatedSearchQuery = searchQuery?.reduce((acc, cur) => {
    const key = Object.keys(cur)[0];

    const found = acc[key];

    if (found) {
      acc[key] = Array.isArray(acc[key]) ? acc[key].concat(cur[key]) : [acc[key], cur[key]];
    } else {
      acc[key] = cur[key];
    }

    return acc;
  }, {} as Record<string, any>);

  const paginationCount = numberOfPages;
  const actualPage = page;
  const canPreviousPage = page > 1;
  const canNextPage = page < paginationCount;

  if (paginationCount === 1) {
    return null;
  }

  const handleUpdatePage = (page: number) => {
    setSearchParams({
      ...updatedSearchQuery,
      page: `${page}`,
    });
  };

  return (
    <div className="flex items-center justify-between sm:justify-center">
      {/* long pagination button form only for desktop view */}
      <div className="hidden gap-0.5 sm:inline-flex">
        <TextButton onClick={() => handleUpdatePage(page - 1)} disabled={!canPreviousPage}>
          <span className="sr-only">Previous</span>
          <RiArrowLeftSLine
            className="size-5 text-gray-700 group-hover:text-gray-900 dark:text-gray-300 group-hover:dark:text-gray-50"
            aria-hidden={true}
          />
        </TextButton>
        <NumberButton onClick={() => handleUpdatePage(1)} active={actualPage === 1}>
          1
        </NumberButton>
        {actualPage > 4 ? (
          actualPage < paginationCount - 2 ? (
            <>
              <NumberButton onClick={() => handleUpdatePage(actualPage - 3)} active={false}>
                ...
              </NumberButton>
              <NumberButton
                onClick={() => handleUpdatePage(actualPage - 2)}
                active={actualPage === actualPage - 1}
              >
                {actualPage - 1}
              </NumberButton>
              <NumberButton onClick={() => handleUpdatePage(actualPage - 1)} active={true}>
                {actualPage}
              </NumberButton>
              <NumberButton
                onClick={() => handleUpdatePage(actualPage)}
                active={actualPage === actualPage + 1}
              >
                {actualPage + 1}
              </NumberButton>
              <NumberButton onClick={() => handleUpdatePage(actualPage + 1)} active={false}>
                ...
              </NumberButton>
            </>
          ) : (
            <>
              <NumberButton onClick={() => handleUpdatePage(2)} active={false}>
                2
              </NumberButton>
              <NumberButton onClick={() => handleUpdatePage(paginationCount - 5)} active={false}>
                ...
              </NumberButton>
              <NumberButton
                onClick={() => handleUpdatePage(paginationCount - 3)}
                active={actualPage === paginationCount - 3}
              >
                {paginationCount - 3}
              </NumberButton>
              <NumberButton
                onClick={() => handleUpdatePage(paginationCount - 2)}
                active={actualPage === paginationCount - 2}
              >
                {paginationCount - 2}
              </NumberButton>
              <NumberButton
                onClick={() => handleUpdatePage(paginationCount - 1)}
                active={actualPage === paginationCount - 1}
              >
                {paginationCount - 1}
              </NumberButton>
            </>
          )
        ) : (
          <>
            <NumberButton onClick={() => handleUpdatePage(2)} active={actualPage === 2}>
              2
            </NumberButton>
            <NumberButton onClick={() => handleUpdatePage(3)} active={actualPage === 3}>
              3
            </NumberButton>
            <NumberButton onClick={() => handleUpdatePage(4)} active={actualPage === 4}>
              4
            </NumberButton>
            <NumberButton onClick={() => handleUpdatePage(5)} active={false}>
              ...
            </NumberButton>
            <NumberButton
              onClick={() => handleUpdatePage(paginationCount - 1)}
              active={paginationCount === page - 1}
            >
              {paginationCount - 1}
            </NumberButton>
          </>
        )}
        <NumberButton
          onClick={() => handleUpdatePage(paginationCount)}
          active={actualPage === paginationCount}
        >
          {paginationCount}
        </NumberButton>
        <TextButton onClick={() => handleUpdatePage(page + 1)} disabled={!canNextPage}>
          <span className="sr-only">Next</span>
          <RiArrowRightSLine
            className="size-5 text-gray-700 group-hover:text-gray-900 dark:text-gray-300 group-hover:dark:text-gray-50"
            aria-hidden={true}
          />
        </TextButton>
      </div>
      <p className="text-xs tabular-nums mr-2 text-gray-500 dark:text-gray-500 sm:hidden">
        Page of <span className="font-medium text-gray-900 dark:text-gray-50">{`${page}`}</span>
        <span className="font-medium text-gray-900 dark:text-gray-50"> {`${paginationCount}`}</span>
      </p>
      {/*  */}
      <div className="inline-flex items-center rounded-md shadow-sm sm:hidden">
        <MobileButton
          position="left"
          onClick={() => handleUpdatePage(page - 1)}
          disabled={!canPreviousPage}
        >
          <span className="sr-only">Previous</span>
          <RiArrowLeftSLine
            className="size-5 text-gray-700 group-hover:text-gray-900 dark:text-gray-300 group-hover:dark:text-gray-50"
            aria-hidden={true}
          />
        </MobileButton>
        <MobileButton
          position="right"
          onClick={() => handleUpdatePage(page + 1)}
          disabled={!canNextPage}
        >
          <span className="sr-only">Next</span>
          <RiArrowRightSLine
            className="size-5 text-gray-700 group-hover:text-gray-900 dark:text-gray-300 group-hover:dark:text-gray-50"
            aria-hidden={true}
          />
        </MobileButton>
      </div>
    </div>
  );
};

export default Pagination;
