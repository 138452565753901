// import { Tooltip } from "@chakra-ui/react";
import { FormattedDate as BaseFormattedDate, FormattedTime } from 'react-intl';
import { Tooltip } from '../Tooltip/Tooltip';
/* eslint-disable-next-line */
export interface FormattedDateProps {
  v: string | Date;
}

export function FormattedDate(props: FormattedDateProps) {
  const { v } = props;
  return (
    <Tooltip className="z-20" content={<FormattedTime timeStyle={'long'} value={v} />}>
      <span className=" ">
        <BaseFormattedDate year="2-digit" month="numeric" day="2-digit" value={v} />
      </span>
    </Tooltip>
  );
}
