import * as React from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

export interface IBreadcrumbsProps {
  pages: {
    name: string;
    href: string;
    current: boolean;
  }[];
}

export const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = (props) => {
  const { pages } = props;

  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page, idx) => (
          <li key={idx}>
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 flex-shrink-0 text-gray-400"
              />
              <Link
                to={page.href}
                aria-current={page.current ? 'page' : undefined}
                className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
