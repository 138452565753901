import * as React from 'react';

const { useState, useCallback } = React;

export const useCheckboxes = (rows: any[]) => {
  const [selectedItems, setSelectedItems] = useState({});

  const setChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setSelectedItems({ ...selectedItems, [event.target.value]: event.target.checked }), [selectedItems]);
  const someSelected = Object.keys(selectedItems).reduce((p: any, c: any) => p || selectedItems[c], false);
  const clearAll = useCallback(() => setSelectedItems({}), []);
  const toggleAll = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setSelectedItems(event.target.checked ? rows.reduce((p: any, c: any) => ({ ...p, [c._id]: true }), {}) : {}), [rows]);
  return { selectedItems, setChecked, setSelectedItems, someSelected, clearAll, toggleAll };
}
