import { useState } from 'react';
import {
  RiCloseCircleLine,
  RiDeleteBin7Line,
  RiFileExcel2Line,
  RiFileLine,
  RiFilePdf2Line,
  RiFileTextLine,
  RiDeleteBinLine,
} from '@remixicon/react';
import { useDropzone } from 'react-dropzone';

import { ProgressBar } from '../ProgressBar/ProgressBar';

import { cx } from '../../utils';

export function FileUpload() {
  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => setFiles(acceptedFiles as File[]),
  });

  const filesList = files.map((file) => (
    <li
      key={file.name}
      className="relative rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-800 dark:bg-gray-950"
    >
      <div className="absolute right-4 top-1/2 -translate-y-1/2">
        <button
          type="button"
          className="rounded-md p-2 text-gray-400 hover:text-gray-500 dark:text-gray-600 hover:dark:text-gray-500"
          aria-label="Remove file"
          onClick={() =>
            setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.name !== file.name))
          }
        >
          <RiDeleteBinLine className="size-5 shrink-0" aria-hidden={true} />
        </button>
      </div>
      <div className="flex items-center space-x-3">
        <span className="flex size-10 shrink-0 items-center justify-center rounded-md bg-gray-100 dark:bg-gray-800">
          <RiFileLine className="size-5 text-gray-700 dark:text-gray-300" aria-hidden={true} />
        </span>
        <div>
          <p className="text-xs font-medium text-gray-900 dark:text-gray-50">{file.name}</p>
          <p className="mt-0.5 text-xs text-gray-500 dark:text-gray-500">{file.size} bytes</p>
        </div>
      </div>
    </li>
  ));

  return (
    <div className="sm:mx-auto sm:max-w-lg">
      {/* <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-50">File Upload</h3> */}
      <div
        {...getRootProps()}
        className={cx(
          isDragActive ? 'border-blue-500 bg-blue-50 dark:border-blue-500 dark:bg-blue-950' : '',
          'mt-2 flex justify-center rounded-lg border border-dashed border-gray-300 px-6 py-6 dark:border-gray-800'
        )}
      >
        <div>
          <RiFileLine
            className="mx-auto size-6 text-gray-400 dark:text-gray-500"
            aria-hidden={true}
          />
          <div className="mt-4 flex text-sm/6 text-gray-700 dark:text-gray-300">
            <p>Drag and drop or</p>
            <label
              htmlFor="file-upload-5"
              className="relative cursor-pointer rounded-md pl-1 font-medium text-blue-500 hover:underline hover:underline-offset-4 dark:text-blue-500"
            >
              <span>choose file</span>
              <input
                {...getInputProps()}
                id="file-upload-2"
                name="file-upload-2"
                type="file"
                className="sr-only"
              />
            </label>
            <p className="pl-1">to upload</p>
          </div>
          <p className="text-center text-xs/5 text-gray-500 dark:text-gray-500">
            XLSX, XLS, CSV up to 25MB
          </p>
        </div>
      </div>
      <h4 className="mt-6 text-sm text-gray-500 dark:text-gray-500">In Progress</h4>
      <ul role="list" className="mt-2 divide-y divide-gray-200 text-sm dark:divide-gray-800">
        {filesList}
        <li className="py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2.5">
              <RiFileExcel2Line
                className="size-7 shrink-0 text-gray-500 dark:text-gray-500"
                aria-hidden={true}
              />
              <div>
                <p className="text-xs font-medium text-gray-900 dark:text-gray-50">
                  Revenue_Q1_2024.xlsx
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-500">12.9 MB</p>
              </div>
            </div>
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 dark:text-gray-600 hover:dark:text-gray-500"
              aria-label="Cancel"
            >
              <RiCloseCircleLine className="size-5 shrink-0" aria-hidden={true} />
            </button>
          </div>
          <div className="mt-2 flex items-center space-x-3">
            <ProgressBar value={71} className="[&>*]:h-1.5" />
            <span className="text-xs text-gray-500 dark:text-gray-500">71%</span>
          </div>
        </li>
        <li className="py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2.5">
              <RiFileExcel2Line
                className="size-7 shrink-0 text-gray-500 dark:text-gray-500"
                aria-hidden={true}
              />
              <div>
                <p className="text-xs font-medium text-gray-900 dark:text-gray-50">
                  Revenue_Q2_2024.xlsx
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-500">22.1 MB</p>
              </div>
            </div>
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 dark:text-gray-600 hover:dark:text-gray-500"
              aria-label="Cancel"
            >
              <RiCloseCircleLine className="size-5 shrink-0" aria-hidden={true} />
            </button>
          </div>
          <div className="mt-2 flex w-full items-center space-x-3">
            <ProgressBar value={21} className="[&>*]:h-1.5" />
            <span className="text-xs text-gray-500 dark:text-gray-500">21%</span>
          </div>
        </li>
      </ul>
      <h4 className="mt-6 text-sm text-gray-500 dark:text-gray-500">Completed Uploads</h4>
      <ul role="list" className="mt-2 divide-y divide-gray-200 dark:divide-gray-800">
        <li className="flex items-center justify-between py-4">
          <div className="flex items-center space-x-2.5">
            <RiFilePdf2Line
              className="size-7 shrink-0 text-gray-500 dark:text-gray-500"
              aria-hidden={true}
            />
            <div>
              <p className="text-xs font-medium text-gray-900 dark:text-gray-50">
                Yearly_Report_2023.pdf
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-500">1.5 MB</p>
            </div>
          </div>
          <button
            type="button"
            className="text-red-500 hover:text-red-600 dark:text-red-500 hover:dark:text-red-400"
            aria-label="Remove"
          >
            <RiDeleteBin7Line className="size-5 shrink-0" aria-hidden={true} />
          </button>
        </li>
        <li className="flex items-center justify-between py-4">
          <div className="flex items-center space-x-2.5">
            <RiFileTextLine
              className="size-7 shrink-0 text-gray-500 dark:text-gray-500"
              aria-hidden={true}
            />
            <div>
              <p className="text-xs font-medium text-gray-900 dark:text-gray-50">
                Forecast_Q3_2024.csv
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-500">2.9 MB</p>
            </div>
          </div>
          <button
            type="button"
            className="text-red-500 hover:text-red-600 dark:text-red-500 hover:dark:text-red-400"
            aria-label="Remove"
          >
            <RiDeleteBin7Line className="size-5 shrink-0" aria-hidden={true} />
          </button>
        </li>
      </ul>
    </div>
  );
}
