import { useEffect, useState } from 'react';
import { asyncify, forEachLimit } from 'async';
import { ServiceTypes } from '@inkcloud/icapi-types';
import { icapi } from '../feathers/feathers-configure';
import { useLocation } from 'react-router-dom';

export type useDataLoaderPaginatedOptions<T, L extends keyof ServiceTypes> = {
  [Property in keyof T]: {
    query?: { [key: string]: any };
    // service: keyof ServiceTypes extends string ? keyof ServiceTypes : never;
    service: L;
    id?: string;
    ignoreLocationChange?: boolean;
  };
};

type Return<T, L extends keyof ServiceTypes> = {
  isLoading: boolean;
  data: {
    [Property in keyof T]: any[];
  };
};

export function useDataLoaderPaginated<T, L extends keyof ServiceTypes>(
  options: useDataLoaderPaginatedOptions<T, L>
): Return<T, L> {
  const initialState = Object.keys(options ?? {}).reduce(
    (acc, cur) => ({ ...acc, [cur]: { data: null, isLoading: false, hasError: false } }),
    {}
  );

  const [state, setState] = useState<{ [key: string]: { isLoading: boolean; data?: any } }>(
    initialState
  );

  const [isInitialLoad, setIsInitialLoad] = useState(false);

  const location = useLocation();

  useEffect(() => {
    forEachLimit(
      Object.keys(options ?? {}),
      1,
      asyncify(async (key) => {
        if (isInitialLoad && options[key].ignoreLocationChange) {
          return;
        }

        const { service, id, query = {}, reload } = options[key];
        setState((prevState) => ({ ...prevState, [key]: { isLoading: true } }));
        let finalResult: any[] = [];

        if (id) {
          const result = await icapi.service(service).get(id, { query });
          finalResult.push(result);
        } else {
          const result = await icapi.service(service).find({ query });
          finalResult = result;
        }

        setState((prevState) => ({
          ...prevState,
          [key]: { isLoading: false, data: finalResult },
        }));
      }),
      (err) => {
        if (err) {
          // @TODO: handle error
        }

        setIsInitialLoad(true);
      }
    );
  }, [location.key]);

  return {
    isLoading: Object.keys(state).some((key) => state[key].isLoading),
    data: Object.keys(state).reduce((acc, key) => ({ ...acc, [key]: state[key].data }), {}),
  } as any;
}
