import { useState } from 'react';

import icLogo from '../../../assets/ic-logo.svg';
import { useForm } from 'react-hook-form';
import { EnvelopeIcon } from '@heroicons/react/20/solid';

import { useNavigate, useLocation } from 'react-router-dom';

import { feathers } from '../../../utils';
import { useAppSelector, useAppDispatch, actions } from '../../../utils/store';
import Input from '../../components/shared/Form/Input/Input';
import { Alert } from '@inkcloud/base-ui';

export default function LoginPage(props) {
  const { handleSubmit, control } = useForm();
  const auth = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async (values: { email: string; password: string }) => {
    setErrorMessage('');
    try {
      dispatch(actions.isAuthenticating());

      const r = await feathers.authenticate({
        strategy: 'global-local',
        email: values?.email?.trim(),
        password: values?.password?.trim(),
      });

      dispatch(actions.setAuthenticated(r.user));

      const urlSearchParams = new URLSearchParams(location.search);
      const redirect = urlSearchParams.get('redirect');
      navigate(redirect ? redirect : '/');
    } catch (e: any) {
      const errMsg = e?.message || 'Something went wrong. Please try again later.';
      setErrorMessage(errMsg);
      dispatch(actions.clearAuthenticated());
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img alt="InkCloud" src={icLogo} className="mx-auto h-24 w-20" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900  dark:text-white">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
          <Input
            id="email"
            control={control}
            rules={{ required: true, minLength: 3, min: 20 }}
            label="Email"
            placeholder="you@inkcloud9.com"
            leadingIcon={EnvelopeIcon}
          />

          <Input
            id="password"
            control={control}
            type={'password'}
            rules={{ required: true, minLength: 3, min: 20 }}
            label="Password"
          />

          {errorMessage && (
            <Alert
              header="Authentication Failure"
              message="Invalid email or password"
              type="error"
            />
          )}
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">InkCloud Command Center</p>
      </div>
    </div>
  );
}
