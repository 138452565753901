import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useList } from '@inkcloud/shared/hooks';
import {
  Badge,
  ListPageTemplate,
  IndeterminateCheckbox,
  EmptyStateActionButton,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const useListReturn = useList({
    feathers: [
      'find',
      'tenants',
      [
        {
          query: {
            $select: ['displayBusinessName', 'key'],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ displayBusinessName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const tableColumns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
            className="-translate-y-[1px]"
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            className="-translate-y-[1px]"
          />
        ),
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Tenant',
        accessorKey: 'displayBusinessName',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Key',
        accessorKey: 'key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          return <Badge variant="success">Active</Badge>;
        },
      },
      {
        header: 'Last edited',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Tenants"
      breadcrumbs={[{ name: 'Tenants', href: '/tenants', current: true }]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      emptyState={{
        title: 'No tenants to show',
        description: 'No tenants have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      filterBar={
        <FilterBar
          // tenants={data?.tenants?.data ?? []}
          // statuses={status}
          value={filter}
          onChange={(v) => setFilter(v)}
          // value={filter}
        />
      }
    />
  );
};

export default ListPage;
