import * as React from 'react';
import { RiBarChartFill } from '@remixicon/react';
import { Button } from '../Button';

interface IEmptyStateProps {
  title?: string;
  description?: string;
  actionButton?: React.ReactNode;
}

const EmptyState: React.FunctionComponent<IEmptyStateProps> = (props) => {
  const {
    title = 'No data to show',
    description = 'No data sources yet available.',
    actionButton,
  } = props;

  return (
    <div className="mt-6 flex h-72 items-center justify-center rounded-md border border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
      <div className="text-center">
        <RiBarChartFill
          className="mx-auto size-7 text-gray-400 dark:text-gray-600"
          aria-hidden={true}
        />
        <p className="mt-2 font-medium text-gray-900 dark:text-gray-50">{title}</p>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-500">{description}</p>
        {actionButton}
      </div>
    </div>
  );
};

interface IEmptyStateActionButtonProps {
  icon?: any;
  title: string;
  onClick: () => void;
}

export const EmptyStateActionButton: React.FunctionComponent<IEmptyStateActionButtonProps> = (
  props
) => {
  const { icon, title, onClick } = props;

  const Icon = icon;
  return (
    <Button className="mt-6" onClick={onClick}>
      <Icon className="-ml-1 size-5 shrink-0" aria-hidden={true} />
      {title}
    </Button>
  );
};

export default EmptyState;
