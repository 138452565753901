import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useList, useDetailPage } from '@inkcloud/shared/hooks';
import {
  Badge,
  ListPageTemplate,
  IndeterminateCheckbox,
  EmptyStateActionButton,
  listTableCheckboxColumn,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const { microsite: id } = useParams();
  const navigate = useNavigate();

  const handleRowClick = (row) => navigate(`/microsites/${id}/users/${row?.original?._id}`);

  const { state: detailState } = useDetailPage({
    api: {
      service: 'microsites',
      method: 'get',
      id,
      query: {
        $select: ['name', 'key'],
      },
    },
  });

  const useListReturn = useList({
    feathers: [
      'find',
      'microsites/customers',
      [
        {
          query: {
            $select: ['firstName', 'lastName', 'email', 'phone', 'groups', 'status'],
            microsite: id,
            $populate: [{ path: 'groups', select: 'name' }],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ displayBusinessName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'User',
        // accessorKey: 'displayBusinessName',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue, row }) => {
          console.log('row', row);
          return `${row.original?.firstName ?? ''} ${row.original?.lastName ?? ''}`;
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          return <Badge variant="success">Active</Badge>;
        },
      },
      {
        header: 'Groups',
        accessorKey: 'groups',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          return (
            <div className="flex gap-1 flex-wrap truncate">
              {getValue()?.map((group: any) => (
                <Badge className=" text-xs" key={group._id}>
                  {group.name ?? ''}
                </Badge>
              ))}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Users"
      breadcrumbs={[
        { name: 'Microsites', href: '/microsites', current: true },
        { name: detailState.data?.name ?? '', href: `/microsites/${id}`, current: true },
        { name: 'Users', href: `/microsites/${id}/users`, current: true },
      ]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      emptyState={{
        title: 'No users to show',
        description: 'No users have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      onRowClick={handleRowClick}
      filterBar={
        <FilterBar
          // tenants={data?.tenants?.data ?? []}
          // statuses={status}
          value={filter}
          onChange={(v) => setFilter(v)}
          // value={filter}
        />
      }
    />
  );
};

export default ListPage;
