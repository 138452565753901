import { useMemo } from 'react';
import { RiPencilLine } from '@remixicon/react';
import { humanize } from 'underscore.string';
import { endOfDay, startOfDay } from 'date-fns';
import { useDataLoaderPaginated, useList } from '@inkcloud/shared/hooks';
import { Badge, FormattedDate, ListPageTemplate, listTableCheckboxColumn } from '@inkcloud/base-ui';

import FilterBar from './FilterBar';
import { useNavigate } from 'react-router';

interface IListPageProps {}

const statusesRaw = [
  'queued-for-generate',
  'in-generate',
  'generate-complete', // Can this one be eliminated and just use awaiting-artwork?
  'generate-failure',

  'awaiting-artwork',
  'pending-dispatch',
  'queued-at-press',
  'in-progress',
  'print-complete',
  'cutting-complete',
  'received-at-press',
  'print-aborted',
  'canceled',
];

const reds = ['generate-failure', 'print-aborted', 'canceled'];
const greens = [
  'generate-complete',
  // 'pending-dispatch',
  'queued-at-press',
  'print-complete',
  'cutting-complete',
];

const yellows = ['awaiting-artwork'];
const blues = ['received-at-press'];

function statusToVariant(status: string): {
  variant: 'error' | 'success' | 'warning' | 'default' | 'neutral';
  color: string;
} {
  if (reds.includes(status)) {
    return { variant: 'error', color: 'bg-red-500 dark:bg-red-500' };
  } else if (greens.includes(status)) {
    return { variant: 'success', color: 'bg-emerald-600 dark:bg-emerald-500' };
  } else if (yellows.includes(status)) {
    return { variant: 'warning', color: 'bg-yellow-500 dark:bg-yellow-500' };
  } else if (blues.includes(status)) {
    return { variant: 'default', color: 'bg-blue-500 dark:bg-blue-500' };
  } else {
    return { variant: 'neutral', color: 'bg-gray-400 dark:bg-orange-600' };
  }
}

const status = statusesRaw
  .map((status) => {
    let color = 'bg-gray-400 dark:bg-orange-600';
    if (reds.includes(status)) {
      color = 'bg-red-500 dark:bg-red-500';
    } else if (greens.includes(status)) {
      color = 'bg-emerald-600 dark:bg-emerald-500';
    } else if (yellows.includes(status)) {
      color = 'bg-yellow-500 dark:bg-yellow-500';
    } else if (blues.includes(status)) {
      color = 'bg-blue-500 dark:bg-blue-500';
    }

    return {
      value: status,
      label: humanize(status),
      color,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const navigate = useNavigate();
  const useListReturn = useList({
    feathers: [
      'find',
      'pre-flights',
      [
        {
          query: {
            $select: ['business', 'createdAt', 'customer', 'orderItem', 'status', 'slaBy'],
            $populate: [
              {
                path: 'business',
                select: 'displayBusinessName key',
              },
              {
                path: 'orderItem',
                select: 'humanId productionStatus',
              },
              {
                path: 'customer',
                select: 'organizationName primaryContact',
              },
            ],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string } } = {};
      if (v.tenant && v.tenant !== 'all') {
        filter.business = v.tenant;
      }
      if (v.status && v.status !== 'all') {
        filter.status = v.status;
      }

      if (v.createdAt) {
        filter.createdAt = {
          $gte: startOfDay(v.createdAt.from).toISOString(),
          $lte: endOfDay(v.createdAt.to).toISOString(),
        };
      }

      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const { data, isLoading } = useDataLoaderPaginated({
    tenants: {
      service: 'tenants' as any,

      query: {
        $sort: { displayBusinessName: 1 },
        $select: ['key', 'displayBusinessName'],
        $limit: 50,
      },
    },
  });

  const tableColumns = useMemo<any[]>(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Date',
        accessorKey: 'createdAt',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
      {
        header: 'Updated',
        accessorKey: 'updatedAt',
        enableSorting: true,

        meta: {
          align: 'text-left',
          columnVisibility: false,
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },

      {
        header: 'Tenant',
        accessorKey: 'business.key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Order Item',
        accessorKey: 'orderItem.humanId',
        enableSorting: true,
        meta: {
          align: 'text-right',
        },
      },
      {
        header: 'Customer',
        accessorKey: 'customer.organizationName',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return (
            <div>
              <span>{getValue()}</span>
            </div>
          );
        },
      },
      {
        header: 'Item Status',
        accessorKey: 'orderItem.productionStatus.jobStatus',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          const { variant, color } = statusToVariant(getValue());
          return <Badge variant={variant}>{humanize(getValue())}</Badge>;
        },
      },

      {
        header: 'PF Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          const { variant, color } = statusToVariant(getValue());
          return <Badge variant={variant}>{humanize(getValue())}</Badge>;
        },
      },
      {
        header: '',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                {/* <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button> */}
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Preflights"
      breadcrumbs={[
        {
          name: 'Preflights',
          href: '/preflights',
          current: true,
        },
      ]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      onRowClick={(row) => {
        console.log('row', row);
        navigate(`/preflights/${row?.original?._id}`);
      }}
      emptyState={{
        title: 'No preflights to show',
        description: 'No preflights have been created yet.',
        // actionButton: (
        //   <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        // ),
      }}
      filterBar={
        <FilterBar
          tenants={(data?.tenants as any)?.data ?? []}
          statuses={status}
          onChange={(v) => setFilter(v)}
          value={filter}
        />
      }
    />
  );
};

export default ListPage;
