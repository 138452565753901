/* eslint-disable no-var,import/first */
// declare var APPENV: { api: { connection: string, socketPath: string, socketConnection: string } };
// console.log('!!APPENV', APPENV);

declare const ICAPI: string;

import { ability, AbilityBuilder } from '@inkcloud/shared/auth';
import { feathersConfigure, expiredTokenDetectionHook } from '@inkcloud/shared/hooks';
import { store, actions } from '../utils/store';
import axios from 'axios';
// import * as io from 'socket.io-client';
import feathers, { type Application, type HookContext } from '@feathersjs/feathers';
// import socketio from '@feathersjs/socketio-client';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import { type ServiceTypes } from '@inkcloud/icapi-types';

const Feathers = feathers<any>();

// if(!APPENV) {
//   var APPENV = { api: { connection : '', socketPath: '', socketConnection: ''}};
// }

// const APPENV = import.meta.env.APPENV;

const icapi: Application<ServiceTypes> = Feathers;
const url = ICAPI;
const location = window.location;

const socketPort = location.port !== '80' ? `:${location.port}` : '';
const socketConnection = `${location.hostname}${socketPort}`;

Feathers.configure(rest(url).axios(axios));
Feathers.configure(auth({ storage: localStorage }));

const socket = feathers();
socket
  // @ts-ignore
  // .configure(
  //   socketio(
  //     io(socketConnection, {
  //       transports: ['websocket'],
  //       upgrade: false,
  //       // path: socketPath,
  //     }),
  //     { timeout: 5000 } as any
  //   )
  // )
  .configure(auth({ storage: localStorage }));
(socket as any).on('reauthentication-error', console.log);

/**
 * This hook is used post-authentication and loads up all needed tenant settings such as Intl and other commonly u
 * used settings
 * @param ctx
 */
const afterAuthLoadSettingsHook = async (ctx: HookContext) => {
  // console.log('in afterAuthLoadSettingsHook')
  try {
    return ctx;
    const settings = await Feathers.service('tenant-settings/intenationalization-options').find({
      query: {},
    });
    // Update the store with the settings
    // store.dispatch();
    console.log('settings', settings);
  } catch (error) {
    console.log('error', error);
    // @TODO: if we cannot load tenant settings, we should log out the user and show a full page error
    // and do a rollbar notice.
  }

  return ctx;
};

/**
 * This hook should be registered after authentication and used to update the Ability instance with the user's permissions
 * @param ctx
 * @returns
 */
const afterAuthSetPermissionsHook = (ctx: HookContext) => {
  // console.log('in the afterAuthSetPermissionsHook');
  if (ctx.result.user.roles2?.length) {
    const r = rolesToPermissionsList(ctx.result?.user?.roles2);
    ability.update(r);
    // rolesToPermissionsList(ctx.result.user.roles2);
  } else {
    ability.update([{ action: 'manage', subject: 'all' }]);
  }
  // console.log('rules from feathers after hook', ability.rules)

  return ctx;
};

Feathers.hooks({
  error: {
    all: [expiredTokenDetectionHook(store, actions)],
  },
});

Feathers.service('authentication').hooks({
  after: {
    create: [afterAuthLoadSettingsHook, afterAuthSetPermissionsHook],
  },
} as any);

feathersConfigure(Feathers);

export function rolesToPermissionsList(roles: any[] = []) {
  /*
    Since these roles are just being introduced, we'll treat a user having none the same as an administrator.
    Once the tenants have had enough time to setup roles and assign them to users, this logic can be removed.
  */
  if (roles.length === 0) {
    return [{ subject: 'all', action: 'manage' }];
  }
  // End

  return roles.reduce(
    (acc, p) => acc.concat(p.permissions.map((p) => ({ subject: p.resource, action: p.action }))),
    []
  );
}

export { Feathers as feathers, icapi, socket, url };
