import * as React from 'react';
import { useList } from '@inkcloud/shared/hooks';
import ListTable from '../../components/ListTable/ListTable';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/Tabs/Tabs';
import { PageHeading, IPageHeadingProps } from '../../components';

type ListReturn = ReturnType<typeof useList>;

interface IListTabPageTemplateProps {
  title: string;
  headerActions?: React.ReactNode;
  breadcrumbs?: IPageHeadingProps['breadcrumbs'];
  defaultTab?: string;
  activeTab?: string;
  preTabSlot?: React.ReactNode;
  onTabChange?: (tab: string) => void;
  useListReturn: ListReturn;
  tabs: {
    tabName: string;
    tabValue: string;
    filterBar: React.ReactNode;
    tableColumns: any[];
    onRowClick?: (row: any) => void;
    emptyState?: {
      title?: string;
      description?: string;
      actionButton?: React.ReactNode;
    };
  }[];
}

export const ListTabPageTemplate: React.FunctionComponent<IListTabPageTemplateProps> = (props) => {
  const {
    title,
    headerActions,
    preTabSlot,
    breadcrumbs,
    activeTab,
    defaultTab,
    onTabChange,
    tabs,
    useListReturn,
  } = props;

  const { state } = useListReturn;

  return (
    <div className="p-6 dark:text-gray-100 text-gray-900">
      <div className="mb-8">
        <PageHeading title={title} actions={headerActions} breadcrumbs={breadcrumbs} />
      </div>

      <div className="mb-8">{preTabSlot}</div>

      <Tabs defaultValue={defaultTab} value={activeTab} onValueChange={onTabChange}>
        <TabsList variant="line" className="mb-6">
          {tabs.map((tab, index) => {
            const { tabName, tabValue } = tab;
            return (
              <TabsTrigger key={index} value={tabValue}>
                {tabName}
              </TabsTrigger>
            );
          })}
        </TabsList>
        <div className="bg-white p-4 sm:p-6 lg:rounded-lg lg:border lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full">
          <div className="ml-2">
            {tabs.map((tab, index) => {
              const { tabValue, filterBar, tableColumns, onRowClick, emptyState } = tab;
              return (
                <TabsContent
                  key={index}
                  value={tabValue}
                  className="space-y-2 text-sm leading-7 text-gray-600 dark:text-gray-500"
                >
                  {state.isLoading ? (
                    <div className="mt-8 min-h-80 flex flex-col bg-white border shadow-sm rounded-md dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                      <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                        <div className="flex justify-center">
                          <div
                            className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                  ) : (
                    <ListTable
                      columns={tableColumns}
                      data={state.queryResult}
                      filterBar={filterBar}
                      // emptyState={emptyState}
                      onRowClick={onRowClick}
                    />
                  )}
                </TabsContent>
              );
            })}
          </div>
        </div>
      </Tabs>
    </div>
  );
};
