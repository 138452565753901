import { Alert, Box, Input, Stack, Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import Cleave from 'cleave.js/react';
import { CleaveWrapper } from '../input-wrapper';
import { useState } from 'react';

const expMonths = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

const date = new Date();
const nowYear = date.getFullYear();
const max = nowYear + 12;
const expYears: { value: string; label: string }[] = [];
for (let i = nowYear; i < max; i++) {
  expYears.push({ value: `${i - 2000}`, label: `${i}` });
}

type CreatePaymentType = {
  authorizeNetClientKey: string;
  authorizeNetApiLoginId: string;
  values: {
    number: string;
    expMonth: string;
    expYear: string;
    cvv: string;
    postalCode: string;
    name: string;
  };
};

type AuthorizePaymentFormProps = {
  authorizeNetClientKey: string;
  authorizeNetApiLoginId: string;
  onSubmit: (v: any) => void;
};

function sendPaymentDataToAnet({
  authorizeNetClientKey,
  authorizeNetApiLoginId,
  values,
}: CreatePaymentType) {
  const authData: any = {};
  authData.clientKey = authorizeNetClientKey;
  authData.apiLoginID = authorizeNetApiLoginId;

  console.log({ authData });
  console.log('values data to net', values);

  const secureData: any = {};
  secureData.authData = authData;
  secureData.cardData = {
    cardNumber: values.number,
    month: values.expMonth,
    year: values.expYear,
    cardCode: values.cvv,
    zip: values.postalCode,
    fullName: values.name,
  };

  return new Promise((resolve) => {
    (window as any).Accept.dispatchData(secureData, resolve);
  });
}

export function AuthorizePaymentForm(props: AuthorizePaymentFormProps) {
  const { authorizeNetClientKey, authorizeNetApiLoginId, onSubmit } = props;

  const [message, setMessage] = useState('');

  const [values, setValues] = useState<CreatePaymentType['values']>({
    number: '',
    expMonth: expMonths[0].value,
    expYear: expYears[0].value,
    cvv: '',
    postalCode: '',
    name: '',
  });

  // console.log('props', props);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    try {
      const res: any = await sendPaymentDataToAnet({
        authorizeNetClientKey,
        authorizeNetApiLoginId,
        values,
      });

      if (res?.messages.resultCode === 'Error') {
        setMessage(res?.messages.message[0].text);
      } else {
        onSubmit({
          authNetOpaqueData: res?.opaqueData,
        });
      }

      console.log('authorize res', res);
    } catch (e: any) {
      const errMsg =
        e?.code < 500
          ? e?.message
          : 'We are having trouble processing your request. Please try again later.';

      setMessage(errMsg);
      console.error('e', e);
    }
  };

  return (
    <Box
      display="inline-block"
      p={4}
      borderWidth={1}
      borderColor="gray.200"
      shadow="sm"
      borderRadius="sm"
    >
      <Text fontSize="sm" mb={2}>
        Card
      </Text>
      <form id="authorize-payment-form" onSubmit={handleSubmit}>
        <Stack direction="row">
          <CleaveWrapper height={10}>
            <Cleave
              name="number"
              className="form-control-cleave"
              placeholder="Credit card number"
              options={{ creditCard: true }}
              onChange={(e) => setValues({ ...values, number: e.target.rawValue })}
            />
          </CleaveWrapper>
          <Box>
            <Input
              placeholder="CVV"
              height={10}
              onChange={(e) => setValues({ ...values, cvv: e.target.value })}
            />
          </Box>

          <Select
            useBasicStyles
            placeholder="Month"
            options={expMonths}
            defaultValue={expMonths[0]}
            onChange={(v: any) => setValues({ ...values, expMonth: v.value })}
          />
          <Select
            useBasicStyles
            placeholder="Year"
            options={expYears}
            defaultValue={expYears[0]}
            onChange={(v: any) => setValues({ ...values, expYear: v.value })}
          />
        </Stack>
        {message && (
          <Alert my={3} status="error">
            {message}
          </Alert>
        )}
      </form>
    </Box>
  );
}

export default AuthorizePaymentForm;
