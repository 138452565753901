import provinces from 'provinces';
import { ModelTypes } from '@inkcloud/icapi-types';

export function currencySymbol(currency = 'USD', locale = 'en-US') {
  const newLocale = locale.replace(/_/g, '-');

  return new Intl.NumberFormat(newLocale, { style: 'currency', currency })
    ?.formatToParts(1)
    ?.find((x) => x.type === 'currency')?.value;
}

export function customerNameAndLinkResolver(
  customer: ModelTypes.Customers,
  micrositeUser?: ModelTypes.MicrositeCustomers,
  micrositeId?: any
) {
  let customerName;
  let customerLink;
  if (micrositeUser) {
    const { firstName, lastName } = micrositeUser;
    customerName = `${firstName ?? ''} ${lastName ?? ''}`;
    customerLink = `/microsites/${micrositeId}/users/${micrositeUser._id}`;
  } else {
    const { organizationName, primaryContact } = customer;
    if (primaryContact) {
      const { firstName, lastName } = primaryContact;
      customerName = `${firstName ?? ''} ${lastName ?? ''}`;
      customerLink = `/customers/${customer.id}`;
    }
  }

  return { customerName, customerLink };
}

export let usStateProvinces = [];
usStateProvinces = provinces
  .filter((p) => p.country === 'US')
  .reduce((prev, next) => {
    prev = prev.concat([{ text: next.name, value: next.short }]);
    // prev[next.short] = next.name;
    return prev;
  }, usStateProvinces);

export const countryStateProvinces = (countryCode: string) => {
  return provinces
    .filter((p) => p.country === (countryCode?.toUpperCase() ?? 'US'))
    .reduce((prev, next) => {
      prev = prev.concat([{ label: next.name, value: next.short }]);
      return prev;
    }, []);
};
