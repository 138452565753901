import * as React from 'react';
import { useParams } from 'react-router';
import {
  AreaChart,
  BarChart,
  Card,
  Badge,
  DefinitionList,
  // FileAttachmentList,
} from '@inkcloud/base-ui';

import { PageHeader } from '../components/PageHeader';
import KPIs from '../components/KPIs';
import RateCardTable, { IRateCardTableProps } from '../components/RateCardTable';
import { useDetailPage, useFeathers } from '@inkcloud/shared/hooks';
import { ModelTypes } from '@inkcloud/icapi-types';

interface IProductPageProps {}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function valueFormatter(number) {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    // notation: 'compact',
    // compactDisplay: 'long',
  });
  return formatter.format(number);
}

// const chartdata = [
//   {
//     date: 'Jan 23',
//     Orders: 2890,
//     Inverters: 2338,
//   },
//   {
//     date: 'Feb 23',
//     Orders: 2756,
//     Inverters: 2103,
//   },
//   {
//     date: 'Mar 23',
//     Orders: 3322,
//     Inverters: 2194,
//   },
//   {
//     date: 'Apr 23',
//     Orders: 3470,
//     Inverters: 2108,
//   },
//   {
//     date: 'May 23',
//     Orders: 3475,
//     Inverters: 1812,
//   },
//   {
//     date: 'Jun 23',
//     Orders: 3129,
//     Inverters: 1726,
//   },
//   {
//     date: 'Jul 23',
//     Orders: 3490,
//     Inverters: 1982,
//   },
//   {
//     date: 'Aug 23',
//     Orders: 2903,
//     Inverters: 2012,
//   },
//   {
//     date: 'Sep 23',
//     Orders: 2643,
//     Inverters: 2342,
//   },
//   {
//     date: 'Oct 23',
//     Orders: 2837,
//     Inverters: 2473,
//   },
//   {
//     date: 'Nov 23',
//     Orders: 2954,
//     Inverters: 3848,
//   },
//   {
//     date: 'Dec 23',
//     Orders: 3239,
//     Inverters: 3736,
//   },
// ];

const ProductPage: React.FunctionComponent<IProductPageProps> = (props) => {
  // get id from url params

  const { id } = useParams();

  const { state } = useDetailPage({
    api: {
      service: 'products-legacy',
      method: 'get',
      id,
      query: {
        $populate: [
          { path: 'parent', select: 'staticName key' },
          { path: 'category', select: 'name' },
        ],
      },
    },
  });

  const [chartData, chartDataReload] = useFeathers('post', 'products-legacy/order-stats', [
    { product: id },
  ]);

  const [invntoryBalanceData, invntoryBalanceReload] = useFeathers(
    'post',
    'products-legacy/inventory-balance-stats',
    [{ product: id }]
  );

  const product: ModelTypes.ProductLegacy & { category: ModelTypes.ProductCategoryLegacy } =
    state.data ?? {};

  const data = [
    {
      name: 'Units On-Hand',
      value: product.inventoryCount ?? '-',
      // change: '+6.1%',
      // changeType: 'positive',
      href: '#',
    },
    {
      name: 'Total Value',
      value: '-',
      // change: '+19.2%',
      // changeType: 'positive',
      href: '#',
    },
    {
      name: 'Order Growth',
      value: '11.3%',
      change: '-1.2%',
      changeType: 'negative',
      href: '#',
    },
  ];

  const isVariant = product.parent;

  const rateCardRates: IRateCardTableProps['rates'] = [];

  if (product.price) {
    const initial: IRateCardTableProps['rates'][number] = {
      quantity: '1',
      unitSell: product.price ?? 0.0,
      unitCost: undefined,
      unitMargin: undefined,
    };
    if (product.cost ?? 0.0) {
      initial.unitCost = product.cost;
    }

    if (!product.priceBreaks?.length) {
      initial.quantity = '1+';
    }

    // initial.unitMargin = `${((initial.unitSell - initial.unitCost) / initial.unitCost) * 100}%`;
    rateCardRates.push(initial);
  }

  const availableCount = (product.inventoryCount ?? 0.0) - (product.allocatedCount ?? 0.0);

  console.log('state', state);
  return (
    // <div className="bg-gray-50 px-8 p-4 sm:p-6 lg:rounded-lg lg:border lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full ">
    <div className="bg-gray-50 px-8 p-4 sm:p-6  lg:border-gray-200 dark:bg-gray-950 lg:dark:border-gray-900 h-full ">
      <div className="mb-8">
        <PageHeader
          title={state.data?.staticName ?? ''}
          sku={state.data?.key ?? ''}
          isActive={true}
          isOutOfStock={product?.inventoryCount === 0}
          type="stock"
        />
      </div>
      {isVariant && (
        <div className="mb-8 rounded-lg bg-blue-50 p-6 ring-1 ring-inset ring-gray-200 dark:bg-gray-400/10 dark:ring-gray-800">
          <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-50">
            This SKU is a product variant of the{' '}
            <span className="font-bold text-blue-800">Good Times Tote</span>.
          </h4>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            To view the parent product, click{' '}
            <a
              href="#"
              className="inline-flex items-center gap-1 text-indigo-600 dark:text-indigo-500"
            >
              Here
            </a>
          </p>
        </div>
      )}

      <div className="">
        <KPIs data={data} />
      </div>

      <div className="grid grid-cols-2 gap-8">
        <Card className="mt-8 p-0 sm:mx-auto sm:w-full">
          <div className="p-6">
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
              Orders
            </p>
            <p className="text-2xl font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {chartData?.data?.total ?? '-'}
            </p>
            <BarChart
              className="mt-8 hidden h-48 sm:block"
              data={chartData?.data?.rows}
              index="date"
              categories={['Orders']}
              // valueFormatter={(number: number) =>
              //   `$${Intl.NumberFormat('us').format(number).toString()}`
              // }
              onValueChange={(v) => console.log(v)}
            />
          </div>
        </Card>
        <Card className="mt-8 p-0 sm:mx-auto sm:w-full">
          <div className="p-6">
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
              Inventory Level
            </p>
            <p className="text-2xl font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {product.inventoryCount ?? '-'}
            </p>

            <AreaChart
              data={invntoryBalanceData.data?.rows}
              index="date"
              categories={['Balance']}
              showLegend={false}
              // showGradient={false}
              yAxisWidth={45}
              valueFormatter={valueFormatter}
              className="mt-8 hidden h-48 sm:block"
            />
          </div>
        </Card>
      </div>

      <section aria-labelledby="rate-card">
        <Card className="mt-8">
          <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
            <div>
              <h2
                id="billing-overview-heading"
                className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              >
                Rate Card
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Cost and Sell amounts at different price breaks
              </p>
            </div>
            <div className="md:col-span-2">
              <h3
                id="current-cycle-heading"
                className="text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Current rate card as of Aug 31, 2024
              </h3>
              <div>
                <div
                  className="w-full overflow-auto whitespace-nowrap mt-4"
                  aria-labelledby="current-cycle-heading"
                >
                  <RateCardTable currency={'USD'} locale={'en_US'} rates={rateCardRates} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="mx-auto my-6 flex w-full items-center justify-between gap-3 text-sm text-gray-500 dark:text-gray-500"
            tremor-id="tremor-raw"
          >
            <div className="h-[1px] w-full bg-gray-200 dark:bg-gray-800"></div>
          </div>

          <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
            <div>
              <h2
                id="billing-overview-heading"
                className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              >
                Inventory
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Inventory details and stock levels
              </p>
            </div>
            <div className="md:col-span-2">
              <div>
                <DefinitionList
                  data={[
                    { name: 'On-Hand', value: product.inventoryCount ?? '-' },
                    { name: 'Allocated', value: product.allocatedCount ?? '-' },
                    { name: 'Available', value: availableCount },
                    { name: 'Total Value', value: '-' },
                    { name: 'UOM', value: product.unitOfMeasure ?? '-' },
                    { name: 'Lead Time', value: product.leadTimeDays ?? '-' },
                    { name: 'Turnover Ratio', value: '5.68' },
                    { name: 'Average Age', value: '97 days' },
                  ]}
                />
              </div>
            </div>
          </div>
        </Card>
      </section>
      <Card className="mt-8">
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50">
              Product Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Product details.</p>
          </div>
          <DefinitionList
            data={[
              { name: 'Name', value: product.staticName },
              { name: 'SKU', value: product.key },
              { name: 'Category', value: product.category?.name },
              { name: 'Created', value: product.createdAt },
              { name: 'Updated', value: product.updatedAt },
              {
                name: 'Description',
                value: <div dangerouslySetInnerHTML={{ __html: product.description }} />,
              },
              // {
              //   name: 'Attachments',
              //   value: (
              //     <FileAttachmentList
              //       data={[
              //         {
              //           name: 'resume_back_end_developer.pdf',
              //           fileSize: '2.4mb',
              //           downloadUrl: '#',
              //         },
              //         {
              //           name: 'coverletter_back_end_developer.pdf',
              //           fileSize: '4.5mb',
              //           downloadUrl: '#',
              //         },
              //       ]}
              //     />
              //   ),
              // },
              {
                name: 'Custom Fields',
                value: (
                  <DefinitionList
                    nested
                    data={
                      product.customFieldsSchema?.map((f) => ({
                        name: f.label,
                        value: f.type,
                      })) ?? []
                    }
                  />
                ),
              },

              {
                name: 'Metadata',
                value: (
                  <DefinitionList
                    nested
                    data={
                      product.metadata?.map((m) => ({
                        name: m.key,
                        value: m.value,
                      })) ?? []
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      </Card>
    </div>
  );
};

export default ProductPage;
