import { Card } from '@inkcloud/base-ui';
import * as React from 'react';

interface IHomePageProps {}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const statusColor = {
  success: 'bg-green-500',
  warning: 'bg-yellow-500',
  danger: 'bg-red-500',
};

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const data1 = [
    { name: 'Recurring revenue', value: '$34.1K', change: '+6.1%', changeType: 'positive' },
    { name: 'Total users', value: '500.1K', change: '+19.2%', changeType: 'positive' },
    { name: 'User growth', value: '11.3%', change: '-1.2%', changeType: 'negative' },
  ];

  const data2 = [
    { status: 'In production', statusColor: 'success' },
    { status: 'Cutting', statusColor: 'success' },
  ];

  return (
    <div className="py-10">
      <header>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-50">
            Command Center
          </h1>
        </div>
      </header>
      <main className="p-6">
        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">yeah</div>

        <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {data1.map((item) => (
            <Card key={item.name}>
              <dd className="flex items-start justify-between">
                <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  {item.value}
                </span>
                <span
                  className={classNames(
                    item.changeType === 'positive'
                      ? 'text-emerald-700 dark:text-emerald-500'
                      : 'text-red-700 dark:text-red-500',
                    'text-tremor-default font-medium'
                  )}
                >
                  {item.change}
                </span>
              </dd>
              <dt className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                {item.name}
              </dt>
            </Card>
          ))}
        </dl>

        <Card className="mt-6 w-1/3">
          <dl>
            {data2.map((item, idx) => (
              <div key={idx} className="flex items-center gap-2 text-xs">
                <span
                  className={classNames(statusColor[item.statusColor], 'size-2.5 rounded-sm')}
                  aria-hidden={true}
                />
                <span className="text-gray-900 dark:text-gray-50">{item.status}</span>

                <span className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  asdf
                </span>
              </div>
            ))}
          </dl>
        </Card>
      </main>
    </div>
  );
};

export default HomePage;
