import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useParams, Link } from 'react-router-dom';
import { useList, useDetailPage } from '@inkcloud/shared/hooks';
import {
  Badge,
  ListPageTemplate,
  IndeterminateCheckbox,
  EmptyStateActionButton,
  listTableCheckboxColumn,
  FormattedDate,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const { microsite: id } = useParams();

  const { state: detailState } = useDetailPage({
    api: {
      service: 'microsites',
      method: 'get',
      id,
      query: {
        $select: ['name', 'key'],
      },
    },
  });

  const useListReturn = useList({
    feathers: [
      'find',
      'user-allotments',
      [
        {
          query: {
            $select: [
              'name',
              'amount',
              'createdAt',
              'expiresAt',
              'availableAmount',
              'name',
              'description',
              'products',
            ],
            microsite: id,
            $populate: [
              {
                path: 'micrositeUser',
                select: ['firstName', 'lastName'],
              },
              {
                path: 'products',
                select: 'key',
              },
            ],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ displayBusinessName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Date',
        accessorKey: 'createdAt',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
      {
        header: 'User',
        accessorKey: 'micrositeUser',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          const { firstName, lastName, _id } = getValue();
          return (
            <Link
              to={`/microsites/${id}/users/${_id}`}
              className="cursor-pointer hover:text-blue-900 dark:hover:text-blue-300"
              onClick={(e) => e.stopPropagation()}
            >
              {firstName ?? ''} {lastName ?? ''}
            </Link>
          );
        },
      },

      {
        header: 'Desc',
        // accessorKey: 'amount',
        enableSorting: true,
        meta: {
          align: 'text-left truncate max-w-xs',
        },
        cell: ({ row }) => {
          return (
            <div>
              <div className="truncate">{row.original?.description ?? ''}</div>
              <div className="flex gap-2">
                {row.original?.products?.map((product: any) => (
                  <Badge key={product.key} variant="success">
                    {product.key}
                  </Badge>
                ))}
              </div>
            </div>
          );
        },
      },

      {
        header: 'Amount',
        accessorKey: 'amount',
        enableSorting: true,
        meta: {
          align: 'text-right text',
        },
      },
      {
        header: 'Available',
        accessorKey: 'availableAmount',
        enableSorting: true,
        meta: {
          align: 'text-right',
        },
      },

      {
        header: 'Expires',
        accessorKey: 'expiresAt',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Allotments"
      breadcrumbs={[
        { name: 'Microsites', href: '/microsites', current: true },
        { name: detailState.data?.name ?? '', href: `/microsites/${id}`, current: true },
        { name: 'Allotments', href: `/microsites/${id}/allotments`, current: true },
      ]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      emptyState={{
        title: 'No allotments to show',
        description: 'No allotments have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      filterBar={
        <FilterBar
          // tenants={data?.tenants?.data ?? []}
          // statuses={status}
          value={filter}
          onChange={(v) => setFilter(v)}
          // value={filter}
        />
      }
    />
  );
};

export default ListPage;
