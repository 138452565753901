import * as React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';

interface IAlertProps {
  header?: string;
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
}

export const Alert: React.FunctionComponent<IAlertProps> = (props) => {
  const { header, message } = props;

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          {header && <h3 className="text-sm font-medium text-red-800">{header}</h3>}
          <div className="mt-2 text-sm text-red-700">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
