import * as React from 'react';
import { Feathers as feathers } from '../feathers/feathers-configure';

export interface QueryResult<T = any> {
  total: number;
  limit: number;
  skip: number;
  data: T[];
}

export interface State<T = any> {
  isError: boolean;
  isLoading: boolean;
  isFinished: boolean;
  data: any;
  error: any;
  errorObj?: any;
  queryResult: QueryResult<T> | T[];
}

type ActionTypes = 'REQUEST_START' | 'REQUEST_END';

export interface Action {
  type: ActionTypes;
  payload?: any;
  method: 'find' | 'get' | 'post';
  error?: boolean;
}

const reducer = (state: State, action: Action): State => {
  const field = action.method === 'find' ? 'queryResult' : 'data';
  switch (action.type) {
    case 'REQUEST_START':
      return {
        ...state,
        isLoading: true,
        isFinished: false,
      };
    case 'REQUEST_END':
      // console.log('action', action);
      return {
        ...state,
        isLoading: false,
        isFinished: true,
        isError: !!action.error,
        errorObj: action.error,
        // ...(action.error ? {} : { data: action.payload }),
        [action.error ? 'error' : field]: action.payload,
      };

    default:
      return state;
  }
};

const queryResultDefaults = {
  total: 0,
  limit: 0,
  skip: 0,
  data: [],
};

const initialState = {
  isError: null,
  isLoading: false,
  isFinished: false,
  data: null,
  queryResult: null,
  error: null,
};

enum ServiceMethods {
  get = 'get',
  find = 'find',
}

async function loadData(
  type: 'find' | 'get' | 'post',
  service: string,
  args: any,
  dispatch: React.Dispatch<Action>
) {
  if (!feathers) {
    throw Error('useFeathers hook requires feathers to be registered with configure()');
  }

  const feathersService = feathers.service(service);
  let results, id, params, data;
  dispatch({ type: 'REQUEST_START', method: type });

  try {
    if (type === 'get') {
      [id, params] = args;
      results = await feathersService.get(id, params);
    } else if (type == 'find') {
      [params] = args;
      results = await feathersService.find(params);
    } else {
      [data] = args;
      results = await feathersService.create(data);
    }

    dispatch({ type: 'REQUEST_END', method: type, payload: results });
  } catch (e) {
    dispatch({ type: 'REQUEST_END', method: type, payload: results, error: e });
  }

  return results;
}

export const useFeathers = (
  type: 'find' | 'get' | 'post',
  service: string,
  args: any[],
  initialLoad: boolean = false
): [State, () => Promise<State>] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  React.useEffect(() => {
    loadData(type, service, args, dispatch);
  }, [JSON.stringify(args)]);
  return [
    state,
    function reload() {
      return loadData(type, service, args, dispatch);
    },
  ];
};
