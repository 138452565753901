import { type HookContext } from '@feathersjs/feathers';

// @TODO: Add typings for store and actions
export const expiredTokenDetectionHook = (store: any, actions: any) => (ctx: HookContext) => {
  /**
   * We should skip hook when actually authenticating as 401s are expected when username/password
   * is wrong or when checking JWT
   */
  if (ctx.path === 'authentication') {
    return ctx;
  }

  if (ctx.error && ctx.error.code === 401) {
    // @TODO: Update the global store to unset the user object.
    // console.log('we caught it in here');
    store.dispatch(actions.clearAuthenticated());
  }

  return ctx;
};
