import * as React from 'react';
import { AreaChart, Card, Badge } from '@inkcloud/base-ui';
import { Breadcrumbs } from '@inkcloud/base-ui';

interface IPageHeaderProps {
  title: string;
  sku?: string;
  isActive?: boolean;
  isOutOfStock?: boolean;
  type: 'stock' | 'on-demand' | 'composite';
}

export const PageHeader: React.FunctionComponent<IPageHeaderProps> = (props) => {
  const { title, sku, isActive, isOutOfStock, type } = props;
  return (
    <div>
      <Breadcrumbs
        pages={[
          { name: 'Products', href: '/products', current: false },
          { name: title, href: '', current: true },
        ]}
      />
      <div className="flex gap-2">
        <h1 className="text-3xl font-bold text-tremor-content-strong tracking-wide dark:text-dark-tremor-content-strong">
          {title}
        </h1>
        <div className="">
          <Badge className="capitalize mr-2" variant={'default'}>
            {type}
          </Badge>
          <Badge className="mr-2" variant={isActive ? 'success' : 'warning'}>
            {isActive ? 'Active' : 'Inactive'}
          </Badge>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="text-sm uppercase font-medium tracking-tight text-gray-600">{sku}</div>
        {isOutOfStock && <Badge variant="error">Out of Stock</Badge>}
      </div>
    </div>
  );
};

export default PageHeader;
