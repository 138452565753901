import { useEffect, useMemo, useRef, useState } from 'react';
import { IndeterminateCheckbox } from './IndeterminateCheckbox/IndeterminateCheckbox';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const listTableCheckboxColumn = {
  id: 'select',
  header: ({ table }) => (
    <IndeterminateCheckbox
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
      onClick={(e) => e.stopPropagation()}
      className="-translate-y-[1px]"
    />
  ),
  cell: ({ row }) => (
    <IndeterminateCheckbox
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
      onClick={(e) => e.stopPropagation()}
      className="-translate-y-[1px]"
    />
  ),
  enableSorting: false,
  meta: {
    align: 'text-left w-12',
  },
};
