import * as React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCheckboxes } from '../use-checkboxes/use-checkboxes';
import { useDebounce } from '../use-debounce/use-debounce';
import { useFeathers } from '../use-feathers/use-feathers';
import { Feathers as feathers } from '../feathers/feathers-configure';

const { useState, useCallback } = React;

export interface UseListProps {
  feathers: ['get' | 'find', string, any[], boolean?];
  filterMap: (v: any, q?: any) => any;
  debounce: number;
  location: any;
  history?: any;
  sort?: any;
  refreshOnLocationChange?: boolean;
}

export const useList = (options: UseListProps) => {
  const {
    feathers: feathersOpts,
    filterMap,
    debounce = 250,
    location,
    history,
    sort: defaultSort = {},
    refreshOnLocationChange = false,
  } = options;
  const [type, service, args] = feathersOpts;

  const navigate = useNavigate();

  // const searchQuery = [];

  const searchParams = new URLSearchParams(location?.search);
  const page =
    location?.state && location?.state?.page !== undefined
      ? location?.state?.page
      : Number(searchParams.get('page') || '1') - 1;

  // for (const [key, value] of searchParams) {
  //   console.log('use lists', [key, value]);

  //   searchQuery.push({
  //     [key]: value,
  //   });
  // }

  // const updatedSearchQuery = searchQuery?.reduce((acc, cur) => {
  //   const key = Object.keys(cur)[0];

  //   console.log('key', key);
  //   const found = acc[key];

  //   if (found) {
  //     acc[key] = Array.isArray(acc[key]) ? acc[key].concat(cur[key]) : [acc[key], cur[key]];
  //   } else {
  //     acc[key] = cur[key];
  //   }

  //   return acc;
  // }, {});

  // console.log('args', args, searchQuery, updatedSearchQuery);

  /**
   * To keep track of when path changes. Used
   * to reload data when path changes, particularly
   * when submitting from a Modal or Drawer that is a nested route
   */
  const initialPathRef = React.useRef(null);
  const lastPath = React.useRef(null);

  if (!initialPathRef.current) {
    initialPathRef.current = location.pathname;
  }
  /**
   * END -----------------------------------------------------
   */

  const [lastPage, setLastPage] = useState<any>(page);
  const [limit, setLimit] = useState(null);
  const [sort, setSort] = useState<any>(defaultSort);
  const [filter, setFilter] = useState(
    location?.state && location?.state?.filter ? location?.state?.filter : {}
  );
  const debouncedFilter = useDebounce(filter, debounce);
  // console.log('debouncedFilter:', debouncedFilter);

  const skipQuery = page > 0 ? { $skip: page * limit } : {};
  const sortQuery = { $sort: sort };

  // Map filter values through supplied function
  const mappedFilter = filterMap ? filterMap(debouncedFilter, args[0].query) : {};
  // console.log('mappedFilter:', mappedFilter);

  // console.log('mappedFilter', mappedFilter);

  // Merge all parts for final query sent to Feathers
  const feathersQuery = {
    query: { ...args[0].query, ...mappedFilter, ...skipQuery, ...sortQuery },
  };

  const [state, reload] = useFeathers(type, service, [feathersQuery]);
  const { queryResult } = state;

  // Since limit is defined on the server, we should save it when it is first returned
  // or if the value changes
  if (queryResult && (queryResult as any).limit && (queryResult as any).limit !== limit) {
    setLimit((queryResult as any).limit);
  }

  // If the location changes, and the new location is the same as the initial location
  // if (lastPath.current !== location.pathname && location.pathname === initialPathRef.current) {
  //   reload();
  // }

  const { selectedItems, clearAll, setChecked, setSelectedItems, someSelected, toggleAll } =
    useCheckboxes(queryResult ? (queryResult as any).data : []);

  // Clear any selected checkboxes when page changes so user can't delete items not on screen
  if (lastPage !== page) {
    if (someSelected) {
      clearAll();
    }
    setLastPage(page);
  }

  // Invoked when user clicks a delete button to delete all selected records
  const handleDelete = useCallback(async () => {
    const toDelete = Object.keys(selectedItems).filter((si) => selectedItems[si]);
    await Promise.all(toDelete.map((i) => feathers.service(service).remove(i)));
    clearAll();
    reload();
  }, [selectedItems]);

  // Helper to navigate to page 1. TODO: This should be improved as if there are any additional
  // query params, they will be lost

  const resetPage = () =>
    navigate(location.pathname, {
      // search: '?page=1',
      state: {
        page: 0,
        filter,
      },
    });
  // const resetPage = () => history?.push({
  // pathname: location.pathname,
  // search: '?page=1',
  // state: {
  //   page: 0,
  //   filter
  // }
  // });

  // Invoked when sorting a table. It will clear any selected checkboxes and navigate to page 1
  const handleSort = useCallback(
    (field: string) => {
      setSort({ [field]: sort[field] ? sort[field] * -1 : (sort[field] = 1) });
      clearAll();
      resetPage();
    },
    [sort]
  );

  // When filter changes, always reset to the first page
  const setFilterWithPageReset = useCallback((v: any) => {
    setFilter(v);
    clearAll();
    resetPage();

    navigate(location.pathname, {
      state: {
        page,
        filter: v,
      },
      replace: true,
    });
    // history?.replace(location.pathname, {
    //   page,
    //   filter: v,
    // })
  }, []);

  // Map from a feathers representation to one for Semantic UI Table
  const mappedSort: any = Object.keys(sort).reduce(
    (r, field) => ({ ...r, [field]: sort[field] === 1 ? 'ascending' : 'descending' }),
    {}
  );

  lastPath.current = location.pathname;

  return {
    state,
    reload,
    handleDelete,
    selectedItems,
    clearAll,
    setChecked,
    setSelectedItems,
    someSelected,
    toggleAll,
    filter,
    setFilter: setFilterWithPageReset,
    sort: mappedSort,
    handleSort,
  };
};
