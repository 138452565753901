import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const initialState: AuthSliceState = {
  isAuthenticated: false,
  isAuthenticating: false,
  user: null,
};

interface AuthSliceState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user: User | null;
};


interface User {
  _id: string;
  email: string;
  business: string; // @NOTE: For Legacy purposes
  firstName: string;
  lastName: string;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    isAuthenticating: (state) => ({ ...state, isAuthenticating: true }),
    setAuthenticated: (state, action: PayloadAction<User>) => ({
      ...state,
      isAuthenticated: true,
      isAuthenticating: false,
      user: action.payload,
    }),
    clearAuthenticated: (state) => ({
      ...initialState,
    }),
  },
});

// interface PrintModalAction {
//   pdfUrl?: string;
//   hasError?: boolean;
//   isOpen: boolean;
//   isLoading: boolean;
// }

// const initialStatePrintModal = {
//   isOpen: false,
//   isLoading: false,
//   hasError: false,
//   pdfUrl: null,
// };

// const printModalSlice = createSlice({
//   name: 'printModal',
//   initialState: initialStatePrintModal,
//   reducers: {
//     setLoading: (state) => ({ ...state, isLoading: true, isOpen: true }),
//     setLoadSuccess: (state, action: PayloadAction<PrintModalAction>) => ({
//       ...state,
//       isOpen: true,
//       isLoading: false,
//       hasError: false,
//       pdfUrl: action.payload,
//     }),
//     setLoadError: (state) => ({
//       ...state,
//       isOpen: true,
//       isLoading: false,
//       hasError: true,
//     }),
//     clear: () => ({
//       ...initialStatePrintModal,
//     }),
//   },
// });

const initialStateArtworkPreview = {
  error: '',
  modal: {
    itemId: '',
    humanId: '',
    artworks: [],
    thumbnails: [],
    isOpen: false,
  },
  isLoading: false,
};

const artworkPreviewSlice = createSlice({
  name: 'artworkPreview',
  initialState: initialStateArtworkPreview,
  reducers: {
    setLoading: (state) => ({ ...state, error: '', isLoading: true }),
    setArtwork: (state, action: PayloadAction<any>) => ({
      ...state,
      modal: {
        ...state.modal,
        artworks: action.payload,
      },
      isLoading: false,
    }),
    setModal: (state, action: PayloadAction<any>) => ({
      ...state,
      modal: {
        ...action.payload,
        isOpen: !state.modal.isOpen,
      },
    }),
    setError(state, action: PayloadAction<any>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    setClear: () => initialStateArtworkPreview,
  },
});

/**
 * This section is just to make the legacy dashboard work. We will remove it after
 */

const initialGlobalState = {
  tenant: {
    publishedContactInfo: {
      domainName: null,
    },
    internationalization: {
      currency: 'USD',
      countryCode: 'US',
      locale: 'en-US',
    },
  },
};

const legacyGlobalSlice = createSlice({
  name: 'globals',
  initialState: initialGlobalState,
  reducers: {
    setTenant: (state, action: PayloadAction<any>) => ({
      ...state,
      tenant: action.payload,
    }),
  },
});

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    // printModal: printModalSlice.reducer,
    artworkPreview: artworkPreviewSlice.reducer,
    globals: legacyGlobalSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const { actions } = authSlice;
