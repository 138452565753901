import {
  RiAppleFill,
  RiCheckboxMultipleFill,
  RiGoogleFill,
  RiMastercardLine,
  RiVisaFill,
} from '@remixicon/react';

import { cx } from '../../../../tremor/utils';

import {
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
} from '@inkcloud/base-ui';
import { Input } from '../../../components/shared/Input';

const status = [
  // array-start
  {
    value: 'completed',
    label: 'Completed',
    color: 'bg-emerald-600 dark:bg-emerald-500',
  },
  {
    value: 'processing',
    label: 'Processing',
    color: 'bg-gray-400 dark:bg-orange-600',
  },
  {
    value: 'failed',
    label: 'Failed',
    color: 'bg-red-500 dark:bg-red-500',
  },
  {
    value: 'refund-requested',
    label: 'Refund requested',
    color: 'bg-violet-500 dark:bg-violet-500',
  },
  // array-end
];

interface DropdownData {
  label: string;
  value: string;
  color?: string;
}

interface FilterBarProps {
  tenants: DropdownData[];

  value: {
    search?: string;
    status?: string;
    tenant?: string;
  };
  onChange: (value: { search?: string; status?: string; tenant?: string }) => void;
}

export default function FilterBar(props: FilterBarProps) {
  const { onChange, value, tenants } = props;

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
      <div className="w-full">
        <Label htmlFor="tenant_1" className="font-medium">
          Tenant
        </Label>
        <Select
          defaultValue="all"
          value={value.tenant ?? 'all'}
          onValueChange={(v) => {
            console.log('tenant change', v);
            onChange({
              ...value,
              tenant: v,
            });
          }}
        >
          <SelectTrigger id="tenant_1" name="tenant_1" className="mt-2 sm:w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem key={'all'} value={'all'}>
              <span className="flex items-center gap-x-2">
                <RiCheckboxMultipleFill
                  className="size-4 shrink-0 text-gray-500 dark:text-gray-500"
                  aria-hidden="true"
                />
                All Tenants
              </span>
            </SelectItem>
            <SelectSeparator />
            {tenants
              .filter((item: any) => item.displayBusinessName)
              .map((item: any) => (
                <SelectItem key={item._id} value={item._id}>
                  <span className="flex items-center justify-between gap-x-3">
                    <span className="truncate">{item.displayBusinessName}</span>
                    <span className="font-normal  text-xs bg-gray-200 px-2 text-gray-700">
                      {/* <Badge className=" font-light" variant="neutral"> */}
                      {item.key?.toUpperCase()}
                      {/* </Badge> */}
                    </span>
                  </span>
                  {/* <Badge variant="neutral">{item.key}</Badge> */}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>

      <div className="w-full">
        <Label htmlFor="skuOrName" className="font-medium">
          SKU or Name
        </Label>
        <Input
          type="search"
          placeholder="Name or SKU..."
          id="search"
          className="mt-2 sm:w-full"
          value={value.search ?? ''}
          onChange={(v) => {
            console.log('status change', v);
            onChange({
              ...value,
              search: v.target.value,
            } as any);
          }}
        />
      </div>

      {/* <div className="w-full">
        <Label htmlFor="status_1" className="font-medium">
          Status
        </Label>
        <Select defaultValue="completed">
          <SelectTrigger id="status_1" name="status_1" className="mt-2 sm:w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            {status.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                <div className="flex items-center gap-x-2.5">
                  <span
                    className={cx(item.color, 'inline-block size-2 shrink-0 rounded-full')}
                    aria-hidden={true}
                  />
                  {item.label}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div> */}
    </div>
  );
}
