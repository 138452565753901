import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// import {  } from 'react-router-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';

import { IntlProvider } from 'react-intl';
import { store, actions } from './utils/store';
import { feathers, socket } from './utils/api';

import App from './app/app';

declare const ENV: string;
declare const HMR: number;

const url = window.location.hostname;

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

if (localStorage['feathers-jwt']) {
  const loadInit = async () => {
    try {
      store.dispatch(actions.isAuthenticating());
      const res = await feathers.reAuthenticate();
      store.dispatch(actions.setAuthenticated(res.user));

      socket.reAuthenticate();
    } catch (e) {
      store.dispatch(actions.isAuthenticating());
      console.log('!!!!!!!!e', e);
      store.dispatch(actions.clearAuthenticated());
    }
  };

  loadInit();
}

function ScrollToTop({ children }) {
  const { pathname, key, state } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, state?.top ?? 0);
  }, [key, state?.top]);

  return children;
}

// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container); // createRoot(container!) if you use TypeScri

const doRender = (root: ReactDOM.Root) => {
  root.render(
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          {/* <CompatRouter> */}
          <ScrollToTop>
            <IntlProvider locale={'en-US'} key={'en-US'}>
              {/* <AnalyticsTracker /> */}
              <App />
              {/* <AbilityContext.Provider value={ability}><AppRoot /></AbilityContext.Provider> */}
            </IntlProvider>
          </ScrollToTop>
          {/* </CompatRouter> */}
        </BrowserRouter>
      </Provider>
    </StrictMode>
  );
};

doRender(root);
