import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { humanize } from 'underscore.string';
import { endOfDay, startOfDay } from 'date-fns';
import { useDataLoaderPaginated, useList } from '@inkcloud/shared/hooks';
import {
  EmptyStateActionButton,
  Badge,
  Dialog,
  DialogContent,
  DialogTrigger,
  FormattedDate,
  ListPageTemplate,
  listTableCheckboxColumn,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const statusesRaw = [
  'queued-for-generate',
  'in-generate',
  'generate-complete', // Can this one be eliminated and just use awaiting-artwork?
  'generate-failure',

  'awaiting-artwork',
  'pending-dispatch',
  'queued-at-press',
  'in-progress',
  'print-complete',
  'cutting-complete',
  'received-at-press',
  'print-aborted',
  'canceled',
];

const reds = ['generate-failure', 'print-aborted', 'canceled'];
const greens = [
  'generate-complete',
  // 'pending-dispatch',
  'queued-at-press',
  'print-complete',
  'cutting-complete',
];

const yellows = ['awaiting-artwork'];
const blues = ['received-at-press'];

function statusToVariant(status: string): {
  variant: 'error' | 'success' | 'warning' | 'default' | 'neutral';
  color: string;
} {
  if (reds.includes(status)) {
    return { variant: 'error', color: 'bg-red-500 dark:bg-red-500' };
  } else if (greens.includes(status)) {
    return { variant: 'success', color: 'bg-emerald-600 dark:bg-emerald-500' };
  } else if (yellows.includes(status)) {
    return { variant: 'warning', color: 'bg-yellow-500 dark:bg-yellow-500' };
  } else if (blues.includes(status)) {
    return { variant: 'default', color: 'bg-blue-500 dark:bg-blue-500' };
  } else {
    return { variant: 'neutral', color: 'bg-gray-400 dark:bg-orange-600' };
  }
}

const status = statusesRaw
  .map((status) => {
    let color = 'bg-gray-400 dark:bg-orange-600';
    if (reds.includes(status)) {
      color = 'bg-red-500 dark:bg-red-500';
    } else if (greens.includes(status)) {
      color = 'bg-emerald-600 dark:bg-emerald-500';
    } else if (yellows.includes(status)) {
      color = 'bg-yellow-500 dark:bg-yellow-500';
    } else if (blues.includes(status)) {
      color = 'bg-blue-500 dark:bg-blue-500';
    }

    return {
      value: status,
      label: humanize(status),
      color,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const useListReturn = useList({
    feathers: [
      'find',
      'production/batches',
      [
        {
          query: {
            $populate: [
              {
                path: 'business',
                select: 'displayBusinessName key',
              },
            ],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string } } = {};
      if (v.tenant && v.tenant !== 'all') {
        filter.business = v.tenant;
      }
      if (v.status && v.status !== 'all') {
        filter.status = v.status;
      }

      if (v.createdAt) {
        filter.createdAt = {
          $gte: startOfDay(v.createdAt.from).toISOString(),
          $lte: endOfDay(v.createdAt.to).toISOString(),
        };
      }

      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const { data, isLoading } = useDataLoaderPaginated({
    tenants: {
      service: 'tenants' as any,
      // action: 'find',
      query: {
        $sort: { displayBusinessName: 1 },
        $select: ['key', 'displayBusinessName'],
        $limit: 50,
      },
    },
  });

  const tableColumns = useMemo<any[]>(
    () => [
      listTableCheckboxColumn,
      {
        header: 'ID',
        accessorKey: 'humanId',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Date',
        accessorKey: 'createdAt',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },
      {
        header: 'Updated',
        accessorKey: 'updatedAt',
        enableSorting: true,

        meta: {
          align: 'text-left',
          columnVisibility: false,
        },
        cell: ({ getValue }) => {
          return <FormattedDate v={getValue()} />;
        },
      },

      {
        header: 'Tenant',
        accessorKey: 'business.key',
        enableSorting: true,
        meta: {
          align: 'text-left',
        },
      },
      {
        header: 'Thumb',
        accessorKey: 'thumbnailStorageUrl',
        enableSorting: false,
        meta: {
          align: 'text-left hidden sm:table-cell',
        },
        cell: ({ getValue }) => {
          const v = getValue();
          const [thumbPath] = v;
          if (!v) {
            return (
              <div className="flex items-center justify-center w-full h-24 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            );
          }
          const url = `https://storage.googleapis.com/icstorage/${thumbPath}`;

          return (
            <Dialog>
              <DialogTrigger asChild>
                <img
                  src={url}
                  alt="Imposition thumbnail"
                  className="max-h-24 max-w-24 rounded sm:w-96 cursor-pointer"
                />
              </DialogTrigger>
              <DialogContent className="sm:max-w-7xl p-8">
                <img src={url} alt="Imposition thumbnail" className="rounded" />
              </DialogContent>
            </Dialog>
          );
        },
        // https://storage.googleapis.com/icstorage/impositions/thumbs/66da25f8f2f99c78da10c972.pdf
      },
      {
        header: 'Specs',
        accessorKey: 'specs',
        enableSorting: false,
        meta: {
          align: 'text-left hidden sm:table-cell ',
        },
        cell: ({ getValue, row }) => {
          const v = getValue();
          return (
            <>
              {/* <span>
                {row?.original?.sheetWidth} x {row?.original?.sheetHeight} in
              </span> */}

              <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3 sm:gap-2 sm:px-0">
                  <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                    Paper
                  </dt>
                  <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    100# Gloss Book
                  </dd>
                </div>
                <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3  sm:px-0">
                  <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                    Coating
                  </dt>
                  <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    None
                  </dd>
                </div>
                <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3  sm:px-0">
                  <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                    Color
                  </dt>
                  <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    4/4
                  </dd>
                </div>
                <div className="px-4 py-0 grid-cols-2 md:grid md:grid-cols-3  sm:px-0">
                  <dt className="text-xs font-medium leading-6 text-gray-900 dark:text-gray-300">
                    Sheets
                  </dt>
                  <dd className="mt-1 text-xs leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    11
                  </dd>
                </div>
              </dl>
            </>
          );
        },
      },

      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: {
          align: 'text-left',
        },
        // in cell, use a Badge component
        cell: ({ getValue }) => {
          const { variant, color } = statusToVariant(getValue());
          return <Badge variant={variant}>{humanize(getValue())}</Badge>;
        },
      },
      {
        header: '',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: {
          align: 'text-right',
        },
        cell: ({ getValue }) => (
          <div className="relative">
            <span>{getValue()}</span>
            <div className="absolute right-0 top-1/2 hidden h-full -translate-y-1/2 items-center bg-tremor-background-muted group-hover:flex dark:bg-dark-tremor-background-muted">
              <div className="inline-flex items-center rounded-tremor-small shadow-tremor-input dark:shadow-dark-tremor-input">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPencilLine className="size-4" aria-hidden={true} aria-label="Edit" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiPlayListAddLine className="size-4" aria-hidden={true} aria-label="Add" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-tremor-small bg-tremor-background px-4 py-2 text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring hover:text-tremor-content-strong focus:z-10 dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis dark:ring-tremor-content-emphasis hover:dark:text-dark-tremor-content-strong"
                  onClick={
                    // add stopPropagation to avoid row selection when clicking button
                    (e) => {
                      e.stopPropagation();
                    }
                  }
                >
                  <RiDeleteBin7Line className="size-4" aria-hidden={true} aria-label="Delete" />
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Impositions"
      breadcrumbs={[
        {
          name: 'Impositions',
          href: '/impositions',
          current: true,
        },
      ]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      emptyState={{
        title: 'No impositions to show',
        description: 'No impositions have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      filterBar={
        <FilterBar
          tenants={(data?.tenants as any)?.data ?? []}
          statuses={status}
          onChange={(v) => setFilter(v)}
          value={filter}
        />
      }
    />
  );
};

export default ListPage;
