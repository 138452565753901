import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability'

export { AbilityBuilder } from '@casl/ability';;

const { can, cannot, build } = new AbilityBuilder(Ability);

can('manage', 'all');

export const ability = build();
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
