import { ModelTypes } from 'packages/icapi-types/lib/src';
import provinces from 'provinces';
import countries from 'world-countries';

export function customerNameAndLinkResolver(
  customer: ModelTypes.Customers,
  micrositeUser?: ModelTypes.MicrositeCustomers,
  micrositeId?: unknown
) {
  let customerName;
  let customerLink;
  let companyName = '';

  if (!customer) {
    return { customerName: '', customerLink: '', companyName: '' };
  }

  let resolvedMicrositeId = micrositeId;
  if ((micrositeId as any)?._id) {
    resolvedMicrositeId = (micrositeId as any)._id;
  }

  if (micrositeUser) {
    const { firstName, lastName } = micrositeUser;
    customerName = `${firstName ?? ''} ${lastName ?? ''}`;
    customerLink = `/microsites/${resolvedMicrositeId}/users/${micrositeUser._id}`;
  } else {
    const { organizationName, primaryContact } = customer;
    if (primaryContact) {
      const { firstName, lastName } = primaryContact;
      customerName = `${firstName ?? ''} ${lastName ?? ''}`;
      customerLink = `/customers/${customer._id}`;
    }

    if (organizationName) {
      companyName = organizationName;
    }
  }

  const fullName = [companyName, customerName]
    .map((v) => v?.trim())
    .filter((v) => v)
    .join(' / ');

  return { customerName, customerLink, companyName, fullName };
}

export function extractValue(row: any, key?: string) {

  if (row.operator === 'is') {
    if (Array.isArray(row.value)) {
      if (key === 'addOns') {
        return {
          $elemMatch: {
            addOnId: { $in: row.value.map((v: Record<string, string>) => v.value) },
          },
        };
      }

      return row.value.map((v: Record<string, string>) => v.value);
    }
    if (key === 'metadata') {
      return {
        $elemMatch: {
          key: row.value?.key,
          value: row.value?.value,
        },
      };
    }
    return row.value?.value || row.value;
  } else if (row.operator === 'between') {
    return {
      $gte: row.value.startValue,
      $lte: row.value.endValue,
    };
  } else if (row.operator === 'like') {
    return {
      $LIKE: row.value,
    };
  } else if (row.operator === 'isNot') {
    if (Array.isArray(row.value)) {
      return {
        $nin: row.value.map((v: Record<string, string>) => v.value),
      };
    }
    return {
      $ne: row?.value?.value || row.value,
    };
  }
}

export function filterQuery(filter: Record<string, any>) {
  const t = Object.keys(filter).reduce((acc: Record<string, any>, key) => {
    if (key.includes('attributes:')) {
      const splitKey = key.split(':');
      if (splitKey.length) {
        acc[splitKey[0]] = {
          [splitKey[1]]: extractValue(filter[key]),
        };
      }
    } else {
      acc[key] = extractValue(filter[key], key);
    }

    return acc;
  }, {});

  // console.log('updated query:', t);

  return t;
}

export function currencySymbol(currency = 'USD', locale = 'en-US') {
  const newLocale = locale.replace(/_/g, '-');

  return new Intl.NumberFormat(newLocale, { style: 'currency', currency })
    ?.formatToParts(1)
    ?.find((x) => x.type === 'currency')?.value;
}

export function readableAddOns(addOns: any[], allAddOns: any, id: string, primaryData?: string) {
  if (addOns && addOns.length > 0) {
    let found;

    if (id) {
      const keyToFind = id !== 'primaryData' ? id : primaryData;
      found = addOns.find(
        (a) => a._id === keyToFind?.toUpperCase() || a.addOnId === keyToFind?.toUpperCase()
      );
    }

    if (found) {
      if (primaryData) {
        const foundKey = allAddOns?.find(
          (a: { key: string }) => a.key.toUpperCase() === id.toUpperCase()
        );

        if (foundKey) {
          const foundValue = foundKey.options?.find(
            (k: { value: string }) => k.value === primaryData
          );
          return `${found.name ? found.name : found.addOnId ? found.addOnId : ''} ${foundValue && foundValue.value === '1' ? '' : foundValue ? foundValue.label : ''
            }`;
        }

        return `${found?.name ?? found.addOnId} ${id === 'primaryData' ? '' : primaryData}`;
      }
      return found.name ? found.name : found.addOnId;
    }
  }

  return id;
}

export let usStateProvinces: { label: string; value?: string }[] = [];
usStateProvinces = provinces
  .filter((p) => p.country === 'US')
  .reduce((prev: Array<{ label: string; value?: string }>, next) => {
    prev = prev.concat([{ label: next.name, value: next.short }]);
    return prev;
  }, usStateProvinces);

export const countryStateProvinces = (countryCode: string) => {
  return provinces
    .filter((p) => p.country === (countryCode?.toUpperCase() ?? 'US'))
    .reduce((prev: Array<{ label: string; value?: string }>, next) => {
      // prev.push({ label: next.name, value: next?.short });
      prev = prev.concat([{ label: next.name, value: next.short }]);
      return prev;
    }, []);
};

export const countryOptions = countries
  ?.map((c) => ({ label: c.name?.common, value: c.cca2 }))
  .sort((a, b) => a.label.localeCompare(b.label));
