import { Navigate, useNavigate, createBrowserRouter, useSearchParams } from 'react-router-dom';

// import { actions, useAppDispatch } from './utils/store';
import { useAppSelector } from '../utils/store';

import LoginPage from './pages/Login/LoginPage';
import '../styles.css';

import { Route, Routes, Link, Outlet } from 'react-router-dom';
// import AppShell from './components/layout/AppShell';
import AppShell2 from './components/layout/AppShell2';

import HomePage from './pages/home/HomePage';
import TenantsList from './pages/tenants/list-page';
import ImpositionsList from './pages/impositions/list-page';
import PreflightsList from './pages/preflights/list-page';
import PreflightsDetailPage from './pages/preflights/detail-page/PreflightsDetailPage';
import ProductsPage from './pages/products/list-page';
import ProductDetailsPage from './pages/products/detail-page';
import ProductCategoriesListPage from './pages/products/categories/ListPage/ListPage';
import ProductAttributesListPage from './pages/products/attributes/ListPage/ListPage';

import DesignTemplatesListPage from './pages/design-templates/ListPage/ListPage';

import MicrositeListPage from './pages/microsites//ListPage/ListPage';
import MicrositeDetailPage from './pages/microsites/DetailPage/DetailPage';

import MicrositesUserListContainer from './pages/microsites/users/ListPage/ListPage';
import MicrositeUserDetailPage from './pages/microsites/users/Detailpage/DetailPage';

import MicrositesUserGroupListContainer from './pages/microsites/groups/ListPage/ListPage';
import MicrositeUserGroupDetailContainer from './pages/microsites/groups/DetailPage/DetailPage';
import MicrositeAllotmentsListContainer from './pages/microsites/allotments/ListPage/ListPage';
import MicrositeProductsListContainer from './pages/microsites/products/ListPage/ListPage';

function RequireAuth({ children }) {
  const auth = useAppSelector((state) => state.auth);
  const [search] = useSearchParams();

  // TEMP - This is here to redirect to legacy dashboard
  if (search.get('_sv') === '1') {
    localStorage.setItem('next-gen', '0');
    window.location.href = window.location.pathname;
    return null;
    // return <Navigate to={{ pathname: location.pathname, search: search.toString() }} replace />;
  }

  if (auth.isAuthenticating) {
    return <div>Loading...</div>;
  }

  return auth.isAuthenticated ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        search: `redirect=${window.location.pathname}${window.location.search}`,
      }}
      replace
    />
  );
}

export function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <AppShell2 />
          </RequireAuth>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="tenants" element={<Outlet />}>
          <Route index element={<TenantsList />} />
        </Route>

        <Route path="design-templates" element={<Outlet />}>
          <Route index element={<DesignTemplatesListPage />} />
          {/* <Route path=":id" element={<ProductDetailsPage />} /> */}
        </Route>

        <Route path="impositions" element={<Outlet />}>
          <Route index element={<ImpositionsList />} />
        </Route>
        <Route path="preflights" element={<Outlet />}>
          <Route index element={<PreflightsList />} />
          <Route path=":id" element={<PreflightsDetailPage />} />
        </Route>
        <Route path="products" element={<Outlet />}>
          <Route index element={<ProductsPage />} />
          <Route path=":id" element={<ProductDetailsPage />} />
        </Route>
        <Route path="products/categories" element={<Outlet />}>
          <Route index element={<ProductCategoriesListPage />} />
          {/* <Route path=":id" element={<ProductDetailsPage />} /> */}
        </Route>
        <Route path="products/attributes" element={<Outlet />}>
          <Route index element={<ProductAttributesListPage />} />
          {/* <Route path=":id" element={<ProductDetailsPage />} /> */}
        </Route>

        <Route path="microsites" element={<Outlet />}>
          <Route index element={<MicrositeListPage />} />
          <Route path=":id" element={<MicrositeDetailPage />} />
        </Route>

        <Route path="microsites/:microsite" element={<Outlet />}>
          <Route index element={<MicrositeDetailPage />} />
          <Route path="users" element={<Outlet />}>
            <Route index element={<MicrositesUserListContainer />} />
            <Route path=":id" element={<MicrositeUserDetailPage />} />
          </Route>
          <Route path="groups" element={<Outlet />}>
            <Route index element={<MicrositesUserGroupListContainer />} />
            <Route path=":id" element={<MicrositeUserGroupDetailContainer />} />
          </Route>

          <Route path="groups" element={<MicrositesUserGroupListContainer />}>
            {/* <Route path="add" element={<MicrositeUserGroupsDetailContainer />} /> */}
            {/* <Route path=":id" element={<MicrositeUserGroupsDetailContainer />} /> */}
          </Route>
          <Route path="allotments" element={<MicrositeAllotmentsListContainer />}>
            {/* <Route path="add" element={<MicrositeUserGroupsDetailContainer />} /> */}
            {/* <Route path=":id" element={<MicrositeUserGroupsDetailContainer />} /> */}
          </Route>
          <Route path="products" element={<MicrositeProductsListContainer />}>
            {/* <Route path="add" element={<MicrositeUserGroupsDetailContainer />} /> */}
            {/* <Route path=":id" element={<MicrositeUserGroupsDetailContainer />} /> */}
          </Route>

          {/*
          <Route path="products" element={<MicrositeProductsListContainer />}>
            <Route path="add" element={<MicrositeProductsDetailContainer />} />
            <Route path=":id" element={<MicrositeProductsDetailContainer />} />
          </Route> */}
        </Route>
      </Route>

      <Route
        path="/page-2"
        element={
          <div>
            <Link to="/">Click here to go back to root page.</Link>
          </div>
        }
      />
    </Routes>
  );
}

export default App;
