import {
  RiAppleFill,
  RiGoogleFill,
  RiMastercardLine,
  RiSearchFill,
  RiVisaFill,
} from '@remixicon/react';

import { cx } from '../../../../../tremor/utils';
import {
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@inkcloud/base-ui';
import { Input } from '../../../../components/shared/Input';

const status = [
  // array-start
  {
    value: 'completed',
    label: 'Completed',
    color: 'bg-emerald-600 dark:bg-emerald-500',
  },
  {
    value: 'processing',
    label: 'Processing',
    color: 'bg-gray-400 dark:bg-orange-600',
  },
  {
    value: 'failed',
    label: 'Failed',
    color: 'bg-red-500 dark:bg-red-500',
  },
  {
    value: 'refund-requested',
    label: 'Refund requested',
    color: 'bg-violet-500 dark:bg-violet-500',
  },
  // array-end
];

interface FilterBarProps {
  value: {
    search?: string;
    status?: string;
  };
  onChange: (value: { search: string; status: string }) => void;
}

export default function FilterBar(props: FilterBarProps) {
  const { onChange, value } = props;

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
      <div className="w-full">
        <Label htmlFor="country_1" className="font-medium">
          Country
        </Label>
        <Input
          type="search"
          placeholder="Name or key..."
          id="search"
          className="mt-2 sm:w-full"
          onChange={(v) => {
            onChange({
              ...value,
              search: v.target.value,
            } as any);
          }}
        />
      </div>
      <div className="w-full">
        <Label htmlFor="status_1" className="font-medium">
          Status
        </Label>
        <Select defaultValue="completed">
          <SelectTrigger id="status_1" name="status_1" className="mt-2 sm:w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            {status.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                <div className="flex items-center gap-x-2.5">
                  <span
                    className={cx(item.color, 'inline-block size-2 shrink-0 rounded-full')}
                    aria-hidden={true}
                  />
                  {item.label}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
