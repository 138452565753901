import * as React from 'react';
import { Card } from '@inkcloud/base-ui';

interface IKPIsProps {
  data: {
    name: string;
    value: string | number;
    change?: string;
    changeType?: string | 'positive' | 'negative';
    href?: string;
  }[];
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const KPIs: React.FunctionComponent<IKPIsProps> = (props) => {
  const { data } = props;

  return (
    <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {data.map((item) => (
        <Card key={item.name}>
          <dd className="flex items-start justify-between">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {item.value}
            </span>
            <span
              className={classNames(
                item.changeType === 'positive'
                  ? 'text-emerald-700 dark:text-emerald-500'
                  : 'text-red-700 dark:text-red-500',
                'text-tremor-default font-medium'
              )}
            >
              {item.change}
            </span>
          </dd>
          <dt className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            {item.name}
          </dt>
        </Card>
      ))}
    </dl>
  );
};

export default KPIs;
