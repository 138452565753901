import * as React from 'react';
import { useController, Control, RegisterOptions, FieldError } from 'react-hook-form';

type Rules = Omit<
  RegisterOptions<any, any>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;

interface IInputProps {
  id: string;
  control: Control;
  rules?: Rules;
  label?: string;
  helpText?: string;
  placeholder?: string;
  cornerHint?: string;
  type?: React.HTMLInputTypeAttribute;
  leadingIcon?: React.ElementType;
  trailingIcon?: React.ElementType;
}

function errorMessage(label: string, error: FieldError | undefined, rules?: Rules) {
  if (!error) return '';

  if (error.type === 'required') {
    return `${label} is required`;
  } else if (error.type === 'minLength') {
    return `${label} must be at least ${rules?.minLength} characters`;
  } else if (error.type === 'min') {
    return `${label} must be at least ${rules?.min}`;
  } else if (error.type === 'maxLength') {
    return `${label} must be at most ${rules?.maxLength} characters`;
  } else if (error.type === 'max') {
    return `${label} must be at most ${rules?.max}`;
  } else if (error.type === 'pattern') {
    return `${label} is not valid`;
  }

  return error.message;
}

const Input: React.FunctionComponent<IInputProps> = (props) => {
  const {
    placeholder,
    type = 'text',
    id,
    control,
    rules,
    label = '',
    helpText = '',
    cornerHint,
    leadingIcon: LeadingIcon,
    trailingIcon: TrailingIcon,
  } = props;

  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({ name: id, control, rules });

  const errorClasses = 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500';

  return (
    <div>
      <div className="flex justify-between">
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900  dark:text-white"
        >
          {label}
        </label>
        {cornerHint && (
          <span id={`${id}-hint`} className="text-sm leading-6 text-gray-500">
            {cornerHint}
          </span>
        )}
      </div>
      <div className="relative mt-2 rounded-md shadow-sm">
        {LeadingIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LeadingIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </div>
        )}
        <input
          type={type}
          placeholder={placeholder}
          {...field}
          className={`block w-full rounded-md border-0 py-1.5 ${LeadingIcon ? 'pl-10' : ''} ${
            TrailingIcon ? 'pr-10' : ''
          } text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ${
            error ? errorClasses : ''
          } sm:text-sm sm:leading-6`}
        />
        {TrailingIcon && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <TrailingIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </div>
        )}
      </div>
      {helpText && (
        <p id={`${id}-help-text`} className="mt-2 text-sm text-gray-500">
          {helpText}
        </p>
      )}
      {error && (
        <p id={`${id}-error`} className="mt-2 text-sm text-red-600">
          {errorMessage(label, error, rules)}
        </p>
      )}
    </div>
  );
};

export default Input;
