import {
  RiAppleFill,
  RiCheckboxMultipleFill,
  RiGoogleFill,
  RiMastercardLine,
  RiVisaFill,
} from '@remixicon/react';

import { cx } from '../../../../../tremor/utils';

import {
  DateRangePicker,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
} from '@inkcloud/base-ui';

// colorize each status appropriately

const payment = [
  // array-start
  {
    value: 'all',
    label: 'All',
    disabled: false,
  },
  {
    value: 'visa-card',
    label: 'Visa card',
    disabled: false,
    icon: RiVisaFill,
  },
  {
    value: 'master-card',
    label: 'Mastercard',
    disabled: false,
    icon: RiMastercardLine,
  },
  {
    value: 'apple-pay',
    label: 'Apple pay',
    disabled: false,
    icon: RiAppleFill,
  },
  {
    value: 'google-pay',
    label: 'Google pay',
    disabled: true,
    icon: RiGoogleFill,
  },
  // array-end
];

interface DropdownData {
  label: string;
  value: string;
  color?: string;
  key?: string;
}

interface FilterBarProps {
  tenants?: DropdownData[];
  statuses?: DropdownData[];
  value: {
    createdAt?: {
      from: Date | undefined;
      to: Date | undefined;
    };
    tenant?: string;
    status?: string;
    // paymentMethod: string;
  };
  onChange: (value: {
    createdAt?: {
      from: Date | undefined;
      to: Date | undefined;
    };
    tenant?: string;
    status?: string;
    // paymentMethod: string;
  }) => void;
}
export default function FilterBar(props: FilterBarProps) {
  const { tenants, statuses, value, onChange = () => null } = props;

  // return null;
  return (
    <div className="grid grid-cols-1 gap-4  sm:grid-cols-2 md:grid-cols-4">
      <div className="w-full">
        <Label htmlFor="date_1" className="font-medium">
          Date
        </Label>
        <DateRangePicker
          id="date_1"
          // defaultValue={{
          //   from: new Date(new Date().setDate(new Date().getDate() - 10)),
          //   to: new Date(),
          // }}
          onChange={(value) => {
            onChange({
              ...(value ?? {}),
              createdAt: {
                from: value?.from,
                to: value?.to,
              },
            });
          }}
          className="mt-2 py-1"
        />
      </div>
      <div className="w-full">
        <Label htmlFor="tenant_1" className="font-medium">
          Tenant
        </Label>
        <Select
          defaultValue="all"
          onValueChange={(v) => {
            console.log('tenant change', v);
            onChange({
              ...value,
              tenant: v,
            });
          }}
        >
          <SelectTrigger id="tenant_1" name="tenant_1" className="mt-2 py-1 sm:w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem key={'all'} value={'all'}>
              <span className="flex  items-center gap-x-2">
                <RiCheckboxMultipleFill
                  className="size-4 shrink-0 text-gray-500 dark:text-gray-500"
                  aria-hidden="true"
                />
                All Tenants
              </span>
            </SelectItem>
            <SelectSeparator />
            {tenants
              ?.filter((item) => (item as any).displayBusinessName)
              .map((item: any) => (
                <SelectItem key={item._id} value={item._id}>
                  <span className="flex items-center justify-between gap-x-3">
                    <span className="truncate">{(item as any).displayBusinessName}</span>
                    <span className="font-normal  text-xs bg-gray-200 px-2 text-gray-700">
                      {/* <Badge className=" font-light" variant="neutral"> */}
                      {item.key?.toUpperCase()}
                      {/* </Badge> */}
                    </span>
                  </span>
                  {/* <Badge variant="neutral">{item.key}</Badge> */}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <div className="w-full">
        <Label htmlFor="status_1" className="font-medium">
          Status
        </Label>
        <Select
          defaultValue="all"
          onValueChange={(v) => {
            console.log('status change', v);
            onChange({
              ...value,
              status: v,
            });
          }}
        >
          <SelectTrigger id="status_1" name="status_1" className="mt-2 py-1 sm:w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem key={'all'} value={'all'}>
              <span className="flex items-center gap-x-2">
                <RiCheckboxMultipleFill
                  className="size-4 shrink-0 text-gray-500 dark:text-gray-500"
                  aria-hidden="true"
                />
                Any Status
              </span>
            </SelectItem>

            {statuses?.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                <div className="flex items-center gap-x-2.5">
                  <span
                    className={cx(item.color, 'inline-block size-2 shrink-0 rounded-full')}
                    aria-hidden={true}
                  />
                  {item.label}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      {/* <div className="w-full">
        <Label htmlFor="payment_method" className="font-medium">
          Payment method
        </Label>
        <Select defaultValue="all">
          <SelectTrigger id="payment_method" name="payment_method" className="mt-2 sm:w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            {payment.map((item) => (
              <SelectItem key={item.value} value={item.value} disabled={item.disabled}>
                <div className="flex items-center gap-x-2">
                  {item.icon ? (
                    <item.icon
                      className={cx(
                        item.disabled
                          ? 'text-gray-400/50 dark:text-gray-600/50'
                          : 'text-gray-700 dark:text-gray-300',
                        'size-4 shrink-0'
                      )}
                      aria-hidden={true}
                    />
                  ) : null}
                  {item.label}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div> */}
    </div>
  );
}
