import { useEffect, useRef, useState } from 'react';
import { RiDownload2Line, RiEqualizer2Line } from '@remixicon/react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../DropdownMenu/DropdownMenu';

import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '../Table/Table';
import EmptyState from '../EmptyState/EmptyState';

import { Button } from '../Button';
import Pagination from '../Pagination/Pagination';

import { cx, focusInput } from '../../utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function IndeterminateCheckbox({ indeterminate, className, ...rest }) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames(
        'size-4 rounded border-tremor-border text-tremor-brand shadow-tremor-input focus:ring-tremor-brand-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:text-dark-tremor-brand dark:shadow-dark-tremor-input dark:focus:ring-dark-tremor-brand-muted',
        className
      )}
      {...rest}
    />
  );
}

interface ListTableProps {
  data: any;
  rows?: any[];
  columns: any[];
  onRowClick?: (row: any) => void;
  filterBar?: React.ReactNode;
  hideLoader?: boolean;
  emptyState?: {
    title?: string;
    description?: string;
    actionButton?: React.ReactNode;
  };
}
export default function ListTable(props: ListTableProps) {
  const {
    data,
    onRowClick = (v) => null,
    columns,
    filterBar,
    emptyState,
    hideLoader = false,
  } = props;

  const {
    title: emptyStateTitle,
    description: emptyStateDescription,
    actionButton,
  } = emptyState ?? {};
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    // Pre-select the 2nd row for demo purpose
    // setRowSelection({ 2: true });
  }, []);

  const columnVisibility = columns.reduce((acc, cur, idx) => {
    if (cur.meta?.columnVisibility === false) {
      acc[cur.accessorKey] = cur.meta.columnVisibility;
    }
    return acc;
  }, {});

  const table = useReactTable({
    data: data?.data ?? [],
    columns: columns,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility,
    },
    state: {
      rowSelection,
    },
  });

  if (!data) {
    return null;
  }

  const isEmpty = data?.data?.length === 0;

  const showingStart = data.skip + 1;
  const showingEnd = data.skip + data.limit > data.total ? data.total : data.skip + data.limit;

  return (
    <div className="h-full flex-col max-h-full">
      <div className="sm:flex justify-between items-end border-b border-gray-200 py-6 dark:border-gray-800 ">
        <div className="mb-6 sm:mb-0">{filterBar}</div>

        <div className="flex space-x-2 align-baseline ">
          <Button variant="secondary" className="gap-2 text-xs px-2 py-1">
            <RiDownload2Line className="-ml-px size-4 shrink-0" aria-hidden={true} /> Export
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button
                type="button"
                className={cx(
                  focusInput,
                  'flex items-center gap-x-2 rounded-md border border-gray-300 bg-white px-3 py-1 text-xs font-medium text-gray-900 shadow-sm transition hover:bg-gray-50 focus:z-10 focus:outline-none dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50 hover:dark:bg-gray-950/50'
                )}
              >
                <RiEqualizer2Line className="-ml-px size-5 shrink-0" aria-hidden={true} />

                <span className="tabular-nums">
                  (
                  {table
                    .getAllColumns()
                    .filter((c) => {
                      return typeof c.columnDef.header !== 'function' && c.columnDef.header !== '';
                    })
                    .reduce((acc, cur) => acc + (cur.getIsVisible() ? 1 : 0), 0)}
                  )
                </span>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Columns</DropdownMenuLabel>

              {table
                .getAllColumns()
                .filter((c) => {
                  return typeof c.columnDef.header !== 'function' && c.columnDef.header !== '';
                })
                .map((column) => (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    disabled={!column.getCanHide()}
                    checked={column.getIsVisible()}
                    onCheckedChange={column.toggleVisibility}
                  >
                    {(column.columnDef?.header as any) ?? '-'}
                  </DropdownMenuCheckboxItem>
                ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {!isEmpty && (
        <>
          <div className="table-wrp block max-h-3/5 h-3/5 overflow-x-scroll grow ">
            <Table className="w-full ">
              <TableHead className="sticky top-0 z-10 ">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    className="border-b border-tremor-border dark:border-dark-tremor-border bg-white dark:bg-dark-tremor-background"
                  >
                    {headerGroup.headers.map((header) => (
                      <TableHeaderCell
                        key={header.id}
                        className={classNames((header.column.columnDef.meta as any).align)}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHeaderCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody className="max-h-full h-full overflow-y-auto">
                {table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    onClick={() => onRowClick(row)}
                    className="group select-none hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <TableCell
                        key={cell.id}
                        className={classNames(
                          row.getIsSelected()
                            ? 'bg-tremor-background-muted dark:bg-dark-tremor-background-muted'
                            : '',
                          (cell.column.columnDef.meta as any).align,
                          'relative'
                        )}
                      >
                        {index === 0 && row.getIsSelected() && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-tremor-brand dark:bg-dark-tremor-brand" />
                        )}
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFoot>
                <TableRow>
                  <TableHeaderCell colSpan={1}>
                    <IndeterminateCheckbox
                      {...{
                        checked: table.getIsAllPageRowsSelected(),
                        indeterminate: table.getIsSomePageRowsSelected(),
                        onChange: table.getToggleAllPageRowsSelectedHandler(),
                      }}
                      className="-translate-y-[1px]"
                    />
                  </TableHeaderCell>
                  <TableHeaderCell colSpan={7} className="font-normal tabular-nums">
                    {Object.keys(rowSelection).length} of {table.getRowModel().rows.length} Page
                    Row(s) selected
                  </TableHeaderCell>
                </TableRow>
              </TableFoot>
            </Table>
          </div>
          <div className="mt-4 flex gap-6 justify-end content-end items-center">
            <div className="text-xs">
              Showing{' '}
              <span className="font-bold">
                {showingStart} - {showingEnd}
              </span>{' '}
              of <span className="font-bold">{data.total}</span>
            </div>
            <Pagination
              data={data}
              // countPerPage={25}
              // totalItems={10000}
              // currentPage={1}
              onChangePage={(page) => console.log('got page')}
            />
          </div>
        </>
      )}
      {isEmpty && <EmptyState title={emptyStateTitle} description={emptyStateDescription} />}
    </div>
  );
}

export { ListTable };
