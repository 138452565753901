import { useMemo } from 'react';
import { RiDeleteBin7Line, RiPencilLine, RiPlayListAddLine } from '@remixicon/react';
import { useList } from '@inkcloud/shared/hooks';
import {
  Badge,
  ListPageTemplate,
  IndeterminateCheckbox,
  EmptyStateActionButton,
  listTableCheckboxColumn,
} from '@inkcloud/base-ui';

import FilterBar from './FilterBar';

interface IListPageProps {}

const ListPage: React.FunctionComponent<IListPageProps> = (props) => {
  const useListReturn = useList({
    feathers: [
      'find',
      'products-legacy/attributes',
      [
        {
          query: {
            $select: ['name', 'hrCode', 'entityAttribute'],
            $populate: [
              {
                path: 'entityAttribute',
                select: 'name',
              },
            ],
          },
        },
      ],
    ],
    filterMap: (v) => {
      const filter: { [key: string]: string | { [key: string]: string | any } } = {};
      if (v.search && v.search !== '') {
        filter.$or = [{ displayBusinessName: { $LIKE: v.search } }, { key: { $LIKE: v.search } }];
      }
      return filter;
    },
    debounce: 250,
    location,
    sort: { createdAt: -1 },
  });

  const { filter, setFilter } = useListReturn;

  const tableColumns = useMemo(
    () => [
      listTableCheckboxColumn,
      {
        header: 'Name',
        accessorKey: 'name',
        enableSorting: true,
        meta: {
          align: 'text-left truncate',
        },
      },

      {
        header: 'Type',
        accessorKey: 'entityAttribute',
        enableSorting: true,
        meta: {
          align: 'text-left truncate',
        },
        cell: ({ getValue }) => {
          return <Badge variant="neutral">{getValue()?.name}</Badge>;
        },
      },
    ],
    []
  );

  return (
    <ListPageTemplate
      title="Product Attributes"
      breadcrumbs={[
        { name: 'Products', href: '/products', current: true },
        { name: 'Attributes', href: '/products/attributes', current: true },
      ]}
      tableColumns={tableColumns}
      useListReturn={useListReturn}
      emptyState={{
        title: 'No attributes to show',
        description: 'No attributes have been created yet.',
        actionButton: (
          <EmptyStateActionButton title="Create New" onClick={() => console.log('create new')} />
        ),
      }}
      filterBar={
        <FilterBar
          // tenants={data?.tenants?.data ?? []}
          // statuses={status}
          value={filter}
          onChange={(v) => setFilter(v)}
          // value={filter}
        />
      }
    />
  );
};

export default ListPage;
